import { Component, Input } from '@angular/core';
import { faMapMarked, faUser, faClock, faHourglass, faAngleUp, faAngleDown, faHiking } from '@fortawesome/free-solid-svg-icons'


@Component({
    selector: 'app-wanderplan-card',
    templateUrl: './wanderplan-card.component.html',
    standalone: false
})
export class WanderplanCardComponent {
  @Input() public data: WanderplanCardData;

  faMapMarked = faMapMarked
  faUser = faUser
  faClock = faClock
  faHourglass = faHourglass
  faAngleUp = faAngleUp
  faAngleDown = faAngleDown
  faHiking  = faHiking
}

export interface WanderplanCardData {
  cardId: string;
  title: string;
  
  contentWithoutCollapse?: string;
  content?: string;
  cardImagePath?: string;
  cardImageAltText?: string;
  startTime?: string;
  locationUrl?: string;
  locationName?: string;
  duration?: string;
  leader?: string;
}
