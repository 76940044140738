export class ContactForm {
    constructor(
      public Name: string,
      public FirstName: string,
      public LastName: string,
      public Email: string,
      public Matter: number,
      public Message: string,
      public Newsletter: boolean,
      public PhoneNumber: string
    ) {}
  
  }
  
  export enum Matters {
    "Buchung/Übernachtung" = "0",
    "Sonstiges" = "1",
    "Mitgliedschaft" = "2",
    "Wandern" = "3",
    "Senioren" = "4",
    "Umweltschutz" = "5",
    "Öffentlichkeitsarbeit/Presse" = "6",
    "Junge Familie" = "7"
    }
