import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    standalone: false
})
export class HeaderComponent implements OnInit, OnDestroy {
  faFacebook = faFacebook;
  faInstagram = faInstagram;

  constructor(private httpClient: HttpClient) { }

  private yearsSubscrption: Subscription;
  public years: number[] = [];
  
  ngOnInit(): void {
    this.yearsSubscrption = this.httpClient.get<number[]>('assets/wanderplan/jahre.json')
      .subscribe(years => this.years = years.filter(year => year != new Date().getFullYear()));
  }
  
  ngOnDestroy(): void {
    this.yearsSubscrption?.unsubscribe();
  }
}
