import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'

import { HomeComponent } from './components/home/home.component'
import { PrivacyComponent } from './components/privacy/privacy.component'
import { LegalComponent } from './components/legal/legal.component'
import { WanderplanComponent } from './components/wanderplan/wanderplan.component';
import { HuetteComponent } from './components/huette/huette.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactComponent } from './components/contact/contact.component';
import { JungeFamilieComponent } from './components/junge-familie/junge-familie.component';
import { EnvironmentComponent } from './components/environment/environment.component';

const routes: Routes = [
  { path: 'datenschutz', component: PrivacyComponent},
  { path: 'impressum', component: LegalComponent},
  { path: 'home', component: HomeComponent },
  { path: 'wanderplan', component: WanderplanComponent},
  { path: 'wanderplan/:year', component: WanderplanComponent},
  { path: 'senioren', component: WanderplanComponent},
  { path: 'senioren/:year', component: WanderplanComponent},
  { path: 'wanderheim', component: HuetteComponent},
  { path: 'about', component: AboutUsComponent },
  { path: 'kontakt', component: ContactComponent },
  { path: 'jungeFamilie', component: JungeFamilieComponent},
  { path: 'umweltschutz', component: EnvironmentComponent},
  { path: '**', redirectTo: 'home'}
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
