<div class="container">
    Eingetragen beim Amtsgericht Zweibrücken, Registernummer VR 20477.<br>

    Der PWV Hauenstein bemüht sich auf seiner Webseite, richtige und vollständige Informationen zur Verfügung zu stellen, übernimmt jedoch keine Haftung oder Garantie für Aktualität, Richtigkeit und Vollständigkeit der Einträge.
    <br>
    Vorstandsvorsitzender<br>
    Johannes Scheib<br>
    E-Mail: j.scheib&#64;pwv-hauenstein.de<br>
    <br>
    Inhaltlich verantwortlich gemäß § 6 MDStV
    PWV Hauenstein
    <br>
    UST-ID-Nr.: 975156402 80
</div>