import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ContactForm } from 'src/app/models/contactForm'

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private httpClient: HttpClient
  ) {
  }
  
  public sendContactForm(form: ContactForm) : Observable<any>
  {
    // TODO: Get base Url (DI)
    return this.httpClient.post<any>(`api/Contact`, form)
  }
}
