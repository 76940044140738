<div class="container-fluid">
<div class="row mb-3">

    <div class="col-lg-4 col-sm-12"id="Neujahr">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-1Friedenskirche.jpg" alt="Henkler-Brünnel im Winter">
        <div class="card-body">
            <h5 class="card-title">Eröffnungswanderung mit Umtrunk.</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>14.01.2024 11:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/49%C2%B011'43.5%22N+7%C2%B050'00.3%22E/@49.1954234,7.8289333,17z/data=!3m1!4b1!4m14!1m7!3m6!1s0x47966732967aa68f:0x422d4d510db4620!2sHauenstein!3b1!8m2!3d49.1930981!4d7.8503597!3m5!1s0x0:0x3c36202a4f91e8ac!7e2!8m2!3d49.1954199!4d7.8334184" target="_blank">Friedenskirche</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>1,5 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Vorstand
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse0" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse0">
                <div class="card-text">   
                   Route ca. 6 km: Friedenskirche, 21er, Haslach, Vierbuchen, Hackdölle, Vier Buchen, Friedenskirche.
Wir begrüßen das neue Wanderjahr bei guter Laune und mit einem Glühwein. Bitte Tassen mitbringen.<br>  
Die Friedenskirche wurde 1972 eingeweiht und wird nun wegen gravierender Bauschäden 
(insbesondere zum Leidwesen der Bürgerinnnen und Bürger "Hinter dem Felsen") abgerissen. 
Im Jahr 2021 wurde das Gotteshaus entweiht. 
Wegen zu hoher Kosten wurde auf eine grundlegende Renovierung verzichtet. 
Das Anwesen ging inzwischen in das Eigentum der Ortsgemeinde, die hier mehrere Bauplätze erschließen möchte, über. 

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Knospen">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-2Knospen.jpg" alt="Knospen im Schnee">
        <div class="card-body">
            <h5 class="card-title">Knospen erkennen im Winterzustand</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>04.02.2024 11:00 Uhr
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Wasgaufreibad+Hauenstein/@49.1892952,7.8294888,14.14z/data=!4m5!3m4!1s0x47966736107c5729:0xcfdee1590f46b9e1!8m2!3d49.1824573!4d7.8509416" target="_blank">Wasgau Freibad</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Vera Klemm
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse1" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse1">
                <div class="card-text">   
                   Route ca. 7,0 km: Freischwimmbad, Hoher Kopf, Hasenteller, Wanderheim (Rast), Freischwimmbad.
Laubgehölz, egal ob Bäume oder Sträucher, scheinen auf den ersten Blick im Winter schwer bestimmbar. 
Wir wollen versuchen, die artspezifische Merkmale zu entdecken und die Art auch ohne Blätter und Blüten zu bestimmen.

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Moosexkursion">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-3Moos.jpg" alt="Moos auf Sandstein">
        <div class="card-body">
            <h5 class="card-title">Exkursion 'ohne Moos nix los'</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>17.03.2024 10:30 Uhr
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/49%C2%B011'43.5%22N+7%C2%B050'00.3%22E/@49.1954234,7.8289333,17z/data=!3m1!4b1!4m14!1m7!3m6!1s0x47966732967aa68f:0x422d4d510db4620!2sHauenstein!3b1!8m2!3d49.1930981!4d7.8503597!3m5!1s0x0:0x3c36202a4f91e8ac!7e2!8m2!3d49.1954199!4d7.8334184" target="_blank">Friedenskirche</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>1.5 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Vera Klemm
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse2" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse2">
                <div class="card-text">   
                   Route ca. 2 km: Hängelstraße, Eisweiher (früher auch "Jubo-Weiher") und zurück.<br> 
Es gibt viele tausende verschiedene Moose und sie kommen fast überall auf der Erde vor.     
Moos kann große Mengen Wasser speichern, was dazu beiträgt das das umgebende Areal feucht hält.    
Welche Moose wir wohl heute kennenlernen werden?

                </div>
            </div>
        </div>
    </div>
</div>
    </div>
<div class="row mb-3">

    <div class="col-lg-4 col-sm-12"id="Durch den Wald">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-4Bühlhof.jpg" alt="der Bühlhof">
        <div class="card-body">
            <h5 class="card-title">Durch den Wald</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>24.03.2024 11:00 Uhr
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://https://www.google.de/maps/place/Wasgauh%C3%BCtte+Schwanheim/@49.1636461,7.8629651,15.54z/data=!4m6!3m5!1s0x47965e1e060f1f7b:0x8c3f0e7f8f749a57!8m2!3d49.1629268!4d7.8708169!16s%2Fg%2F12dpx6ds6?entry=ttu" target="_blank">Wasgauhütte Schwanheim</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>3.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Jutta und Michael Keiser
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse3" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse3">
                <div class="card-text">   
                   Route ca. 8 km: Wasgauhütte, Kühhungerfels, Aussichtspunkt Honigfels, Oberschlettenbacher Höhenweg, Galgenfelsen, 
Richtung Bühlhof, Richtung Bärenbrunnerhof, Wasgauhütte.<br>
In Oberschlettenbach ist in der Dorfmitte auf einem Schild am Brunnen folgendes zu lesen: "Wer täglich einen Schoppen schafft, 
hat hundert Jahre Manneskraft, nur wenn er nicht schon früher stirbt und sich somit den Spaß verderbt". Mehr zu  
<a href="https://www.vg-bad-bergzabern.de/gemeinden/oberschlettenbach" target=_blank> Oberschlettenbach</a>

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Lebensader Oberrhein">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-5Rheinniederung.jpg" alt="Blick auf einen Rheinniederung">
        <div class="card-body">
            <h5 class="card-title">Lebensader Oberrhein</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>21.04.2024 11:00 Uhr
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/search/Rathausplatz,+Hauenstein/@49.1914931,7.8545825,18.14z?entry=ttu" target="_blank">Rathausplatz Hauenstein</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.5 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Gabi und Johannes Scheib
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse4" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse4">
                <div class="card-text">   
                   2. Treffunkt ca. 12:00 Uhr: Parkplatz <a href="https://www.google.de/maps/search/Parkplatz+Friedhof+Wagh%C3%A4usel/@49.2279049,8.5033084,15.46z?entry=ttuFriedhof" target=_blank> Friedhof Waghäusel</a> 
Route (ca. 18 m Höhenunterschied) ca. 8 km: Durch die Wagbachniederung. Parkplatz Friedhof Waghäusel - Eremitage Waghäusel – Rundgang Naturschutzgebiet Wagbachniederungen – Parkplatz<br>
Das Naturschutzgebiet Wagbachniederung ist eine Feuchtfläche in einem rechtsrheinischen Altrheinarm in der Nähe von Waghäusel. 
Mit seinen Moor-, Schilf- und Wasserflächen bildet es einen Brut- und Rastplatz für zahlreiche, 
zum Teil auch seltene, Vogelarten von europaweiter Bedeutung. Entlang unseres Weges gibt es zwei Beobachtungsstationen, 
die uns, mit etwas Glück, besonders nah an die Vogelwelt herankommen lassen.
Der Schwerpunkt der Wanderung liegt nicht auf der Zurücklegung der vorgegebenen Strecke, 
sondern auf der Beobachtung der Pflanzen- und Vogelwelt. Deswegen werden wir uns dafür viel Zeit nehmen. 
Die Mitnahme eines Fernglases wird empfohlen.
Eine Abschlussrast erfolgt nach Absprache mit den Teilnehmern.
Die Altrheine entstanden durch die Begradigung des Rheins durch Johann Gottfried Tulla: <a href="https://www.planet-wissen.de/natur/fluesse_und_seen/der_rhein/pwiejohanngottfriedtullabegradigerdesrheins100.html" target=_blank> Wissenswertes über die Rheinbegradigung</a>

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Vogelstimmenwanderung">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-6Kiebitz.jpg" alt="Kiebitz, Vogel des Jahres 2024">
        <div class="card-body">
            <h5 class="card-title">Vogelstimmenwanderung</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>28.04.2024 9:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/49%C2%B011'08.5%22N+7%C2%B051'49.3%22E/@49.185768,7.8611359,15.86z/data=!4m4!3m3!8m2!3d49.185698!4d7.863697?entry=ttu" target="_blank">Karmelkloster</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.5 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Vera Klemm und Josef Strubel
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse5" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse5">
                <div class="card-text">   
                   Auf dem Bild Vogel des Jahres 2024, der Kiebitz.<br>
Route ca. 2 km: über die Felder bis zum ehemaligen Karmelitinerkloster.<br>
Auch in diesem Jahr werden wir vieles über die Stimmen und das Leben der Vögel, 
die wir hören und sehen, lernen. Wir freeuen uns über eine rege Teilnahme. 

                </div>
            </div>
        </div>
    </div>
</div>
    </div>
<div class="row mb-3">

    <div class="col-lg-4 col-sm-12"id="Hunsrück">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-7Muster.jpg" alt="Totholz">
        <div class="card-body">
            <h5 class="card-title">Nationalpark Hunsrück</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>09.05.24 -12.05.24
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Penny+Markt/@49.1961499,7.8338854,16.42z/data=!4m5!3m4!1s0x4796673e3e88dd3d:0x280ef94724eb6493!8m2!3d49.1957!4d7.83834" target="_blank">Penny-Markt</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>Mehrtagestour
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Uwe Schumacher
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse6" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse6">
                <div class="card-text">   
                   Begrenzte Teilnehmerzahl. Verbindliche Anmeldung bis spätestens 31.03.2024 bei Michael Keiser erforderlich! <br>
E-Mail: <a _ngcontent-qpp-c12=""href="mailto:m.keiser@pwv-hauenstein.de"> m.keiser@pwv-hauenstein.de</a> 

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Bärlauchtour">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-8Bärlauch.jpg" alt="ein Bärlauchfeld">
        <div class="card-body">
            <h5 class="card-title"><del>Bärlauchtour</del> entfällt. Ersatztour siehe 'Home'</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>26.05.2024 11:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Penny+Markt/@49.1961499,7.8338854,16.42z/data=!4m5!3m4!1s0x4796673e3e88dd3d:0x280ef94724eb6493!8m2!3d49.1957!4d7.83834" target="_blank">Penny-Markt</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>3.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Jutta und Michael Keiser
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse7" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse7">
                <div class="card-text">   
                   2. Treffpunkt: ca. 12.00 Uhr Bienwaldmühle.
Route ca. 10 km: durch den Bienwald entlang der Lauter. Abschluss nach Wunsch.

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Naturschutzgebiet">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-9Naturschutzgebiet.jpg" alt="Naturschutzgebiet">
        <div class="card-body">
            <h5 class="card-title">Durch das Beeder Naturschutzgebiet</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>02.06.2024 09:30
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Parkplatz/@49.305788,7.3061386,18.17z/data=!4m15!1m8!3m7!1s0x4795daad95f56681:0xb1334d404f1b6799!2sSportanlage,+Sandweg+11,+66424+Homburg!3b1!8m2!3d49.3061434!4d7.3063931!16s%2Fg%2F11c6rgcsb0!3m5!1s0x4795dbf89c461fc5:0x1fe45574c249dfb5!8m2!3d49.3061401!4d7.3067544!16s%2Fg%2F11g0mhdzg0?entry=ttu" target="_blank">Penny-Markt</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>4.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Uwe Schumacher
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse8" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse8">
                <div class="card-text">   
                   2.Treffpunkt ca. 10:30 Uhr: <a href="https://www.google.de/maps/place/Parkplatz/@49.305788,7.3061386,18.17z/data=!4m15!1m8!3m7!1s0x4795daad95f56681:0xb1334d404f1b6799!2sSportanlage,+Sandweg+11,+66424+Homburg!3b1!8m2!3d49.3061434!4d7.3063931!16s%2Fg%2F11c6rgcsb0!3m5!1s0x4795dbf89c461fc5:0x1fe45574c249dfb5!8m2!3d49.3061401!4d7.3067544!16s%2Fg%2F11g0mhdzg0?entry=ttu">Sportheim SV Beeden, Sandweg 11, 66424 Homburg-Beeden.</a>
Route ca. 12 km: Beeder Biotop, Taubertal, Richtung Kirkel, Biosphärenreservat Bliesgau, Beeden.

                </div>
            </div>
        </div>
    </div>
</div>
    </div>
<div class="row mb-3">

    <div class="col-lg-4 col-sm-12"id="Lambertskreuz">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-10Lambertskreuz.jpg" alt="PWV Hütte Lambertskreuz">
        <div class="card-body">
            <h5 class="card-title">Zum Lambertskreuz</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>14.07.2024 10:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Penny+Markt/@49.1961499,7.8338854,16.42z/data=!4m5!3m4!1s0x4796673e3e88dd3d:0x280ef94724eb6493!8m2!3d49.1957!4d7.83834" target="_blank">Penny-Markt</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>4.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Birgit und Thomas Braun
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse9" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse9">
                <div class="card-text">   
                   Route ca. 13 km: Rotsteig, Forsthaus Silbertal, Mitttlerer Stoppelkopf, Lambertskreuz (Mittagsrast), Engschleif, Rotsteig. 310 Höhenmeter. 
Mittelschwere Wanderung.

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Zillertaler">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-11Hochgebirge.jpg" alt="Blick über die Berge">
        <div class="card-body">
            <h5 class="card-title">Zillertaler Alpen</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>28.07.2024 06:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Am+Weimersberg+%26+Wei%C3%9Fenburger+Stra%C3%9Fe,+76846+Hauenstein/@49.1889593,7.8585457,16.71z/data=!4m5!3m4!1s0x4796674fbf490559:0x5c425aab6716753c!8m2!3d49.187673!4d7.8626491" target="_blank">Ankerskreuz</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>Mehrtages-Tour
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Helga Klein (Anmeldung!)
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse10" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse10">
                <div class="card-text">   
                   Wir wandern vom 28.07. bis 01.08.2024 durch die Zillertaler Alpen. Kondition und Trittsicherheit werden vorausgesetzt.
Verbindliche Anmeldung bis 31.05.24 erforderlich bei Helga Klein Tel.: 06392 9937676.
Programm (Änderungen vorbehalten)<br>
Abfahrt: 6.00 Uhr Ankerskreuz<br>
1. Tag: Anfahrt ins Zillertal. Wanderung vom Schlegeis Speichersee (1782m) zum
Friesenberghaus (2498m) – Übernachtung.<br>
2. Tag: Aufstieg zum „Hohen Riffler“ (3231m) 2 ½-3 Std. bzw. zum „Steinmännchenberg“.
Am Nachmittag Wanderung zur Olpererhütte (2388m) – Übernachtung.<br>
3. Tag: Am Schlegeisspeicher vorbei (1782m) zum Furtschaglhaus (2295m) – Übernachtung.<br>
4. Tag: Aufstieg zum „Schönbichler Horn“ (3134m) und auf gleichem Weg zurück zum
Furtschaglhaus (2295m) – Übernachtung. Übergang zur Berliner Hütte (2042m) nur für „Geübte“ möglich.
5. Tag: Abstieg zum Stausee bzw. von der Berliner Hütte zum Gasthof „Breitlahner“ (1256m)
und Rückfahrt.    

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Schwarzwald">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-12Todtnau.jpg" alt="Hängebrücke">
        <div class="card-body">
            <h5 class="card-title"><del>Schwarzwald-Schluchten</del>. Fällt leider aus.</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>24.08.2024 09:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Penny+Markt/@49.1961499,7.8338854,16.42z/data=!4m5!3m4!1s0x4796673e3e88dd3d:0x280ef94724eb6493!8m2!3d49.1957!4d7.83834" target="_blank">Penny-Markt</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2 Tage
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Madeleine und Raymund Burkhard
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse11" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse11">
                <div class="card-text">   
                   Diese Tour muss leider entfallen.<br>
Route:  Wanderfahrt mit 1x Übernachtung
Verbindliche Anmeldung bis 31.05.24, E-Mail: r.burkhard@pwv-hauenstein.de, Tel. 06392-2857
Samstag: Gertelsbacher Wasserfälle und Todtnauer Wasserfälle.
Sonntag: Schluchtentour und Orgelmuseum in Waldkirch.

                </div>
            </div>
        </div>
    </div>
</div>
    </div>
<div class="row mb-3">

    <div class="col-lg-4 col-sm-12"id="Grummbeerebroore">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-13Grummbeere.jpg" alt="Grummbeere auf der Buchenholz-Glut">
        <div class="card-body">
            <h5 class="card-title">Grummbeerebroore</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>14.09.2024 12:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Jugendzeltplatz-Hauenstein/@49.1869991,7.8576334,15.83z/data=!4m5!3m4!1s0x4796674629f6f6a9:0xa037f9dbbc592355!8m2!3d49.1844835!4d7.8622594" target="_blank">Im Stopper (Jugenzeltplatz)</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>Ende offen
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Vorstand
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse12" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse12">
                <div class="card-text">   
                   Wir halten die Tradition hoch. Geselliges Beisammensein mit pfälzischen Spezialitäten.
Route: "s´Dorf naus, an de Ranch vebei in de Stopper"
Geschirr etc. bitte mitbringen!
Tourenbeschreibung: Der Name ist Programm.
Der Ursprung des "Grummbeerebroore" ist aus geschichtlichen Erzählungen wie folgt bekannt: nach der Ernte wurde auf dem Kartoffelacker 
ein Feuer aus Kartoffelkraut entzündet und darin die Kartoffeln gegart. Zum Anderen hatten früher die Waldarbeiter aus frisch geschlagenem 
Buchenholz ein Feuer entzündet und in der daraus gewonnen Glut die Kartoffeln zum Mittagessen zubereitet. Daraus entstand die heutige Tradition, die insbesondere in
Hauenstein von vielen Vereinen gepflegt wird.

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Reifenberg">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-14Wiese.jpg" alt="Wiese mit Heuballen">
        <div class="card-body">
            <h5 class="card-title">Kapellenweg Reifenberg</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>22.09.2024 11:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Penny+Markt/@49.1961499,7.8338854,16.42z/data=!4m5!3m4!1s0x4796673e3e88dd3d:0x280ef94724eb6493!8m2!3d49.1957!4d7.83834" target="_blank">Penny-Markt</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>3.5 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Jutta und Michael Keiser
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse13" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse13">
                <div class="card-text">   
                   Route ca. 11 km: Im Sickingerland rund um Reifenberg. Abwechslungsreiche Strecke durch Wiesentäler, Wälder und herrlichen Rundumblicke.   
Abschluss nach Wunsch.

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Baum des Jahres">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-15Mehlbeere.jpg" alt="Baum des Jahres, die Mehlbeere">
        <div class="card-body">
            <h5 class="card-title">Baum des Jahres</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>27.10.2024 10:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Grillplatz+Hauenstein/@49.1858917,7.8369789,16.75z/data=!4m10!1m2!2m1!1sGrillplatz+76846+Hauenstein!3m6!1s0x47966796ed18b16f:0xe99b9fe7c16b8432!8m2!3d49.185803!4d7.8394482!15sChtHcmlsbHBsYXR6IDc2ODQ2IEhhdWVuc3RlaW6SAQRwYXJr4AEA!16s%2Fg%2F11svrxb14c?entry=ttu" target="_blank">Grillplatz</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.5 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Vera Klemm
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse14" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse14">
                <div class="card-text">   
                   Route ca. 5 km: Die Echte Mehlbeere ist Baum des Jahres 2024. Wissenswertes über unsere heimische Mehlbeere, 
die viele wohl gar nicht kennen, gibt es bei dieser Wanderung zu erfahren. 
Im Hinblick auf den Klimawandel sollte der anspruchslosen und anpassungsfähigen Mehlbeere in Zukunft mehr Augenmerk 
geschenkt werden, um klimastabile Wälder zu erhalten. 

                </div>
            </div>
        </div>
    </div>
</div>
    </div>
<div class="row mb-3">

    <div class="col-lg-4 col-sm-12"id="Elsass">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-16Weinberg.jpg" alt="Weinberg">
        <div class="card-body">
            <h5 class="card-title">Herbst im Elsass</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>13.10.2024 11:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Am+Weimersberg+%26+Wei%C3%9Fenburger+Stra%C3%9Fe,+76846+Hauenstein/@49.1889593,7.8585457,16.71z/data=!4m5!3m4!1s0x4796674fbf490559:0x5c425aab6716753c!8m2!3d49.187673!4d7.8626491" target="_blank">Ankerskreuz</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Gabi und Johannes Scheib
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse15" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse15">
                <div class="card-text">   
                   Route ca. km: Zwischen Cleebourg und Drachenbronn. 
Elsass - Wein und Baumwipfel. Abschluss nach Wunsch.

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Höllenberg">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-17Winterkirchel.jpg" alt="Das Winterkirchel">
        <div class="card-body">
            <h5 class="card-title">Spirkelbacher Höllenberg</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>10.11.2024 11:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Katharinenkapelle/@49.1933617,7.857715,17.23z/data=!4m6!3m5!1s0x4796679c5e55d533:0x67493e3dddffd785!8m2!3d49.1932474!4d7.8593402!16s%2Fg%2F11glvj6d95?entry=ttu" target="_blank">Katharinenkapelle</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>4.5 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Michael Keiser
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse16" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse16">
                <div class="card-text">   
                   Route ca. 15 km: Steinbach - Spirkelbach - Ochsenborn Brunnen - Höllenberg - Friedrcihsfelsen - Höllenfelsen - Spirkelbach - Felder - Katharinenkapelle.
Abschluss nach Wunsch.

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Abschlusswanderung">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/24-18Wanderheim.jpg" alt="Wanderheim Dicke Eiche">
        <div class="card-body">
            <h5 class="card-title">Jahres-Abschlusswanderung</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>22.12.2024 11:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/1886+Genussfabrik./@49.1969025,7.8349783,17.87z/data=!4m15!1m8!3m7!1s0x4796672401377cb3:0x378e35c4e1f030d!2sWaldenburgerstra%C3%9Fe,+76846+Hauenstein!3b1!8m2!3d49.1960142!4d7.8348853!16s%2Fg%2F1xpwhqpt!3m5!1s0x479667a5d30acd41:0x344f931600221ef!8m2!3d49.19722!4d7.83784!16s%2Fg%2F11q41g7htg?entry=ttu" target="_blank">Ankerskreuz</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Vorstand
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse17" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse17">
                <div class="card-text">   
                   Route ca. 6 km: Kreuzfelsen - Kahler-Felsen - Hühnerstein - Wanderheim Dicke Eiche mit Abschlussrast.

                </div>
            </div>
        </div>
    </div>
</div>
    </div>
<div class="row mb-3">

    <div class="col-lg-4 col-sm-12"id="Zusatzangebote">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/23Mountainbike.jpg" alt="Mountainbikerin">
        <div class="card-body">
            <h5 class="card-title">Zusätzliche Angebote für E-Biker</h5>
            <div class="card-subtitle">
                <a data-toggle="collapse" href="#collapse18" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse18">
                <div class="card-text">   
                   Touren je nach Streckenverlauf zwischen 40 km – 60 km Länge.
Fahrtüchtige Räder, entsprechende Bekleidung und Beherrschung des E-Bike werden vorausgesetzt.
Die Strecken sind teils Fahrradwege, gut zu befahrende Waldwege und schmälere Abschnitte im Wald sind auch möglich.
Bei entsprechender Teilnahme können die Touren Samstag, Sonntag oder Feiertagen ganzjährig durchgeführt werden, 
entsprechende Wetterverhältnisse vorausgesetzt. Kurzfristige bzw. flexible Terminansetzungen werden angestrebt.
Die Startzeiten, z.B. morgens, mittags, abends können nach Absprache gewählt werden. <br>

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Junge Familie">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/23JungeFamLogo.jpg" alt="PWV Logo Junge Familie">
        <div class="card-body">
            <h5 class="card-title">Junge Familie</h5>
            <div class="card-subtitle">
                <a data-toggle="collapse" href="#collapse19" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse19">
                <div class="card-text">   
                   Da es im vergangenen Wanderjahr öfter zu Terminüberschneidungen kam und spontane Terminverschiebungen nötig waren, 
oder nur wenige Familien an den Aktivitäten teilnehmen konnten, wollen wir für dieses Wanderjahr einige Termine vorerst offen lassen
und nach Absprache mit euch organisieren. 
Natürlich werden alle Termine und nähere Infos frühzeitig über den Hauensteiner Bote, hier über unsere Webseite, 
über Facebook und unseren Newsletter „Waldnachrichten“ per E-Mail bekannt gegeben. 
Folgende Aktivitäten sind vorgesehen:
<ul>
<li>März/April Spaziergang durch das Stephanstal (Queichtal)</li>
<li>Mai/Juni	Ausflug ins „Didi Land“ nach Morsbronn im Elsass</li> 
<li> 06. September	Hüttenübernachtung</li> 
<li> im Oktober Keschdewanderung zur Wasgauhütte Schwanheim</li> 
<li> 30. November Adventsbasteln</li> 
</ul>   
Kontakt und weitere Informationen: Esther Keiser: Tel: 06392/409331
E-Mail: <a _ngcontent-qpp-c12=""href="mailto:e.keiser@pwv-hauenstein.de">e.keiser@pwv-hauenstein.de</a>

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Hauptverein">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/23Logo.jpg" alt="PWV Logo">
        <div class="card-body">
            <h5 class="card-title">Alle Veranstaltungen PWV Hauptverein</h5>
            <div class="card-subtitle">
                <a data-toggle="collapse" href="#collapse20" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse20">
                <div class="card-text">   
                   <b>Veranstaltungen 2024 PWV Hauptverein e.V.</b>
<ul>
<li> 2./5. März	Workshop „Karte & Kompass“, Rodalben</li>
<li> 16./17. März	PWV-Wanderführerausbildung, Rodalben </li>
<li> 22. April	Jedermannwanderung</li>
<li> 06. April Delegiertenversammlung, Pirmasens</li>
<li> 10.–17. April oder 18.-25. April PWV-Erlebniswanderreise nach „Zypern – Insel der Götter“</li>
<li> 20. April	PWV-Kulturtag, Kaiserslautern</li>
<li> 25. Mai	Jedermannwanderung</li>
<li> 25. Mai PWV-Familienwanderung mit der Kräuterfee der AOK </li>
<li> 13. Mai	Delegiertenversammlung, Rockenhausen</li>
<li> 27.5.-05.06. PWV-Genusswanderreise an die Costa Brava</li>
<li> 01. Juni PWV-Schorlewanderung, Deidesheim</li>
<li> 21. September	Jedermannwanderung</li>
<li> 12. Oktober	Treffen der ausgebildeten PWV-Wanderführer</li>
<li> 12. Oktober	PWV-Naturschutz-Tag</li>
<li> 19.-20. Oktober Workshop „Karte/ Kompass/ GPS“, Rodalben</li>
</ul>
<b>Weitwanderungen</b>
<ul>
<li> April	Nordic Walking Halbmarathon, Rheingönheim </li>
<li> 04. Mai	Marathon rund um den Donnersberg</li>
<li> 08. Juni	Marathon auf dem Brunnenwanderweg, Heltersberg</li>
<li> 15. Juni	Wandermarathon, Wachenheim</li>
<li> 28. September	Marathon auf dem Felsenwanderweg, Rodalben</li>
</ul>
<b>Veranstaltungen für Familien und Jugendliche 2024</b>
<ul>  
<li> 17. Februar	Winterverbrennung, Olsbrücken</li> 
<li> 23.-25. Februar „Jugendarbeit: lustig, praktisch, gut“ (JuLeiCa), Mainz</li>
<li> 09. März	Frühjahrsjugendwartetagung im Raum Kaiserslautern</li>
<li> 30. April	„Nacht der Hexen“, Wachenheim</li>
<li> 18.-20. Mai	Pfingstzeltlager, Schmitshausen od. Otterbach </li>
<li> 04.–16. Juni	Sport & Spiel-Wochenende</li>
<li> 30.08.-01.09.PWV-Familienwochenende, Jugendherberge Dahn</li>
<li> 07. September	Herbstjugendwartetagung, Kaiserslautern</li>
<li> 28.-29. September Mittelalter-Wochenende, Jugendherberge Burg Lichtenberg</li>
</ul>

                </div>
            </div>
        </div>
    </div>
</div>
    </div>
</div>