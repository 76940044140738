import { Component, OnInit } from '@angular/core';
import { faMapMarked, faUser, faClock, faHourglass, faAngleUp, faAngleDown, faHiking } from '@fortawesome/free-solid-svg-icons'
@Component({
  selector: 'app-senioren',
  templateUrl: './senioren.component.html'
})
export class Senioren2024Component implements OnInit {

  constructor() { }
  faMapMarked = faMapMarked
  faUser = faUser
  faClock = faClock
  faHourglass = faHourglass
  faAngleUp = faAngleUp
  faAngleDown = faAngleDown
  faHiking = faHiking
  ngOnInit(): void {
  }

}