<div class="container-fluid">
    <div class="row">
        <div class="card col-lg-6 col-sm-12">
            <img class="card-img-top" src="assets/imgs/Home/25Wanderheim.jpg" alt="Das Wanderheim Dicke Eiche">
            <div class="card-body">
                <h5 class="card-title">Pfälzerwald-Verein Hauenstein 1913 e.V.</h5>
                <div class="card-text">
                    <p>
                        Willkommen auf unserer Homepage <br>
                        Schauen Sie doch einfach mal wieder rein, vielleicht gibt es etwas Neues.
                    </p>

                    <h5>Wanderheim</h5>
                    <p>Unsere <a href="https://www.pwv-hauenstein.de/wanderheim#restaurant" target=_blank>
                            Öffnungszeiten</a>
                        Telefon Gaststätte: 06392 3596
                    </p>

                    <h5>Übernachtungen buchen</h5>
                    <p>Ihre Anfrage und Buchung können Sie gerne per E-Mail richten an:
                        <a _ngcontent-qpp-c12=""
                            href="mailto:buchung@pwv-hauenstein.de">buchung&#64;pwv-hauenstein.de</a><br>
                        Buchungs-Telefonnummer: +49 172 717 39 76<br>
                        <a href="https://www.gruppenhaus.de/plananzeige.php?hs=7784&anzmonate=12&sprache=de&colteil=%239400d3&colbelegt=%23ff0000&plannr=1"
                            target=_blank>Belegungsplan</a>
                    </p>
                    <h5>Ehrenamt</h5>
                    <p>Sie wollen uns gerne unterstützen? Sowohl das Wanderheim "Dicke Eiche" mit Gaststätte und
                        Übernachtungsbereich,
                        als auch das ganze Vereinsgeschehen wird von uns im Ehrenamt mit großem Engagement betrieben.
                    </p>
                    Möglichkeiten zum Mitwirken:
                    <ul>
                        <li>Hüttendienst</li>
                        <li>"Junge Familie"</li>
                        <li>Umwelt-/Naturschutz</li>
                        <li>Wanderbetrieb</li>
                        <li>Sie haben eigene Ideen zum Mitwirken?</li>
                    </ul>
                    <p>Es gibt viel zu tun. Sie wollen mit anpacken oder uns ideell unterstützen?
                        Melden Sie sich unter <a href="mailto:info@pwv-hauenstein.de">info&#64;pwv-hauenstein.de</a> <br>
                        Wir freuen uns auf Sie.
                    </p>
                    <h5>Auf dem Laufenden bleiben</h5>
                    Sie wollen auf dem Laufenden bleiben? Senden Sie eine E-Mail an:
                    <a _ngcontent-qpp-c12=""
                        href="mailto:waldnachrichten@pwv-hauenstein.de">waldnachrichten&#64;pwv-hauenstein.de</a>
                </div>
            </div>
        </div>

        <div class="card col-lg-6 col-sm-12">
            <img class="card-img-top" src="assets/imgs/Home/25Moos.jpg" alt="Moos ">
            <div class="card-body">
                <h5 class="card-title">Aktuelles</h5>
                <div class="card-text">

                    <h5>Die nächsten Wanderungen</h5>
                    <p>Sonntag, 30.03.2025 10:00 Uhr, Exkursion: "Kleines Moos ganz groß". Unsere Vorständin für Natur-/Umweltschutz, Vera Klemm, 
                       freut auf viele Teilnehmerinnen und Teilnehmer.
                       Weitere Infos zur: <a href="https://www.pwv-hauenstein.de/wanderplan#Moos" target=_blank> Exkursion</a>
                    </p>
                    <h5>Unsere Senioren</h5>
                    <p>  
                        Mittwoch, 09.04.2025 14:00 "Osterwanderung".
                        Weitere Infos zur: <a href="https://www.pwv-hauenstein.de/senioren#Osterwanderung" target=_blank> Osterwanderung</a>
                    </p>     
                    <h5>Gäste</h5>
                    <p>
                        Gäste sind zu allen Veranstaltungen herzlich willkommen. 
                    </p>    

                       <div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>