<div class="container">
    <h3>Datenschutzerklärung des PWV</h3>
    <p>
        Verantwortliche im Sinne der Datenschutzgesetze und DS-GVO sind:<br>
        Martin Brandl und Dieter Gröger,<br>
        Pfälzerwald-Verein e.V., Hauptgeschäftsstelle, Fröbelstraße 24, 67433 Neustadt an der Weinstraße
    </p>
    
    <h5>Erfassung allgemeiner Informationen</h5>
    <p>
        Wenn Sie auf unsere Webseite zugreifen, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet Service Providers und Ähnliches. Hierbei handelt es sich ausschließlich um Informationen, welche keine Rückschlüsse auf Ihre Person zulassen. Diese Informationen sind technisch notwendig, um von Ihnen angeforderte Inhalte von Webseiten korrekt auszuliefern und fallen bei Nutzung des Internets zwingend an. Anonyme Informationen dieser Art werden von uns statistisch ausgewertet, um unseren Internetauftritt und die dahinter stehende Technik zu optimieren.
    </p>

    <h5>Kontaktformular</h5>
    <p>
        Treten Sie per E-Mail oder Kontaktformular mit uns in Kontakt, werden die von Ihnen gemachten Angaben zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.
    </p>

    <h5>Löschung bzw. Sperrung der Daten</h5>
    <p>
        Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit. Wir speichern Ihre personenbezogenen Daten daher nur so lange, wie dies zur Erreichung der hier genannten Zwecke erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen vielfältigen Speicherfristen vorsehen. Nach Fortfall des jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die entsprechenden Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.
    </p>

    <h5>Ihre Rechte auf Auskunft, Berichtigung, Sperre, Löschung und Widerspruch</h5>
    <p>
        Sie haben das Recht, jederzeit Auskunft über Ihre bei uns gespeicherten personenbezogenen Daten zu erhalten. Ebenso haben Sie das Recht auf Berichtigung, Sperrung oder, abgesehen von der vorgeschriebenen Datenspeicherung zur Geschäftsabwicklung, Löschung Ihrer personenbezogenen Daten.
        <br>
        Damit eine Sperre von Daten jederzeit berücksichtigt werden kann, müssen diese Daten zu Kontrollzwecken in einer Sperrdatei vorgehalten werden. Sie können auch die Löschung der Daten verlangen, soweit keine gesetzliche Archivierungsverpflichtung besteht. Soweit eine solche Verpflichtung besteht, sperren wir Ihre Daten auf Wunsch.
        <br>
        Sie können Änderungen oder den Widerruf einer Einwilligung durch entsprechende Mitteilung an uns mit Wirkung für die Zukunft vornehmen.
    </p>

    <h5>Änderung unserer Datenschutzbestimmungen</h5>
    <p>
        Wir behalten uns vor, diese Datenschutzerklärung gelegentlich anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z. B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
    </p>

    <h5>Datenschutzerklärung für Neumitglieder</h5>
    <p>
        Wir möchten Sie darüber informieren, dass die von Ihnen in Ihrer Beitrittserklärung angegebenen personenbezogenen Daten (Name, Adresse, Geburtstag sowie ggf. Telefonnummer, E-Mail-Adresse und Bankverbindung) auf den EDV-Systemen der jeweiligen PWV-Ortsgruppe des Pfälzerwald-Vereins gepeichert und für Verwaltungszwecke des Vereins verarbeitet und genutzt werden. Verantwortliche Stelle ist die gewählte Ortsgruppe.
        <br>
        Mit der Mitgliedschaft in der jeweiligen PWV-Ortsgruppe ist zugleich eine Mitgliedschaft im Dachverband „Pfälzerwald-Verein e.V.“ verbunden.
        <br>
        Wir sichern Ihnen zu, Ihre personenbezogenen Daten vertraulich zu behandeln und nicht an Dritte weiterzugeben. Hiervon sind die Daten von Vorstandsmitgliedern, Fachwarten und Wanderführern ausgenommen, deren Kontaktdaten zur Erfüllung der satzungsgemäßen Aufgaben innerhalb der Verbandsstrukturen an den Pfälzerwald-Verein e.V. für die
        interne Kommunikation weitergegeben werden können. Die jeweilige PWV-Ortsgruppe
        veröffentlicht die Kontaktdaten ihrer Vorstände sowie ggf. Wanderführer u.U. auch auf der Internetseite des Vereins.
        <br>
        Sie können jederzeit schriftlich Auskunft über die bezüglich Ihrer Person gespeicherten
        Daten erhalten und Korrektur verlangen, soweit die bei der jeweiligen PWV-Ortsgruppe gespeicherten Daten nicht richtig sind. Wenn die gespeicherten Daten für die Abwicklung der Verwaltungsprozesse im Pfälzerwald-Verein nicht erforderlich sind, können Sie auch eine Sperrung, und gegebenenfalls eine Löschung Ihrer personenbezogenen Daten verlangen.
        <br>
        Der Pfälzerwald-Verein e.V. sendet Ihnen vierteljährlich die Mitgliederzeitschrift „Pfälzerwald“ zu. Hierzu wird Ihre Adresse von der jeweiligen PWV-Ortsgruppe an den Pfälzerwald-Verein e.V. weitergeleitet und dort verwaltet und ggf. aktualisiert. Sollten Sie die Zustellung nicht wünschen, können Sie dem Versand beim Pfälzerwald-Verein e.V. jederzeit schriftlich widersprechen.
        <br>
        Nach einer Beendigung der Mitgliedschaft werden Ihre personenbezogenen Daten spätestens zum Ende des nächsten Jahres nach der Beendigung der Mitgliedschaft gelöscht, soweit sie nicht, entsprechend den steuerrechtlichen oder sonstigen gesetzlichen Vorgaben, aufbewahrt werden müssen. Hiervon sind die Daten ehemaliger Funktionsträger und Mitglieder mit Ehrungen der jeweiligen PWV-Ortsgruppe ausgeschlossen, die weiterhin elektronisch archiviert werden.
        <br>
        Eine Nutzung Ihrer personenbezogenen Daten für Werbezwecke findet weder durch die
        die jeweiligen PWV-Ortsgruppe noch den Pfälzerwald-Verein e.V. statt.
        <br>
        Mit dem Beitritt zur der jeweiligen PWV-Ortsgruppe wird bestätigt, dass die Datenschutzhinweise für Neumitglieder zur Kenntnis genommen und akzeptiert wurden.
    </p>
</div>
