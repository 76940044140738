<!doctype html>
<html lang="de">
  <head>
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="description" content='Finden Sie alle Informationen über den PWV Hauenstein und das Wanderheim "Dicke Eiche" direkt auf unserer Homepage! Wir freuen uns Sie begrüßen zu dürfen.'/>
  </head>
  <body style="display: flex; flex-direction: column; height: 100%; min-height: 100vh;">
    <!-- Header -->
    <app-header style="flex: 0 0 auto"></app-header>

    <!-- routes -->
    <router-outlet style="flex: 0 1 auto"></router-outlet>

    <div style="flex: 1 1 auto"></div>
    <!-- Footer -->
    <app-footer style="flex: 0 0 auto"></app-footer>

  </body>
</html>