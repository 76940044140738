<div class="container-fluid">
  <div class="row">
    <div class="card col-lg-6 col-sm-12" id="Junge Familie">
      <img class="card-img-top" src="assets/imgs/JungeFamilie/JungeFam_PWV_Logo.jpg" alt="PWV Logo Junge Familie">
      <div class="card-body">
        <h5 class="card-title">"Junge Familie"</h5>
        <div class="card-text">
          <h5>Macht mit</h5>
          <p>Hallo an alle junge Familien.
          „Schnuppert“ doch einfach mal ganz unverbindlich rein, bei unseren Angeboten 2024 für junge Familien.
          Wir freuen uns über eure Wünsche, Anregungen und natürlich auch über euer Mitwirken.
          Zu den hier erwähnten und etwaigen weiteren kurzfristig geplanten Events,
          gibt es zeitnah unter pwv-hauenstein.de, im facebook, Hauensteiner Bote und natürlich
          von Esther Keiser weitere Informationen.
          Kontakt E-Mail: <a href="mailto:e.keiser@pwv-hauenstein.de"> e.keiser&#64;pwv-hauenstein.de</a>
          </p>
    
        </div>
      </div>
    </div>

    <div class="card col-lg-6 col-sm-12" id="Jahresprogramm">
      <img class="card-img-top" src="assets/imgs/JungeFamilie/Junge Familie.jpg" class="card-img" alt="eine Familie">
      <div class="card-body">
        <h5 class="card-title">Unser Jahresprogramm "Junge Familie"</h5>
        <div class="card-text" id="Stein">
          <h5>Jahresprogramm 2024</h5>
          Da es im vergangenen Wanderjahr öfter zu Terminüberschneidungen kam und spontane Terminverschiebungen nötig waren, 
          oder nur wenige Familien an den Aktivitäten teilnehmen konnten, wollen wir für dieses Wanderjahr einige 
          Termine vorerst offen lassen und nach Absprache mit euch organisieren. 
          Natürlich werden alle Termine und nähere Infos frühzeitig über den Hauensteiner Bote, hier über unsere Webseite, 
          über Facebook und unseren Newsletter „Waldnachrichten“ per E-Mail bekannt gegeben. <br>
          Folgende Aktivitäten sind vorgesehen:
          <ul>
          <li>März/April Spaziergang durch das Stephanstal (Queichtal)</li>
          <li>Mai/Juni	Ausflug ins „Didi Land“ nach Morsbronn im Elsass</li> 
          <li> 06. September	Hüttenübernachtung</li> 
          <li> im Oktober Keschdewanderung zur Wasgauhütte Schwanheim</li> 
          <li> 30. November Adventsbasteln</li> 
          </ul>   
          <p>Kontakt und weitere Informationen: Esther Keiser: Tel: 06392/409331
          E-Mail: <a _ngcontent-qpp-c12=""href="mailto:e.keiser@pwv-hauenstein.de">e.keiser&#64;pwv-hauenstein.de</a>
          </p>
          <h5>Aktionen PWV Hauptverein</h5>
          Unser Hauptverein bietet (unter anderen) zahlreiche Aktionen speziell für junge Familien. Siehe: <a href="https://www.pwv-hauenstein.de/wanderplan#Hauptverein" target=_blank> Veranstaltungen Hauptverein</a>

        </div>
      </div>
    </div>
  </div>
</div>