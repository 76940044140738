<div class="container-fluid"> 
  <div class="row">
    <div class="card col-lg-6 col-sm-12" id= "Müll weg">
      <div class="card-body">
            <h5 class="card-title">Umwelt-/Naturschutz</h5>
        <div class="card-text">

          <h5>Naturkundliche Projekte/Exkursionen 2024</h5>
          <ul>
            <li>04.02.2024 11:00 Uhr: <a href="https://pwv-hauenstein.de/wanderplan/2024#Knospen im Schnee" target=_blank> "Knospen im Winterzustand erkennen"</a></li>
            <li>17.03.2024 10:30 Uhr: <a href="https://pwv-hauenstein.de/wanderplan/2024#Moosexkursion" target=_blank> "Ohne Moos nix los"</a></li>           
            <li>28.04.2024 09:00 Uhr: <a href="https://pwv-hauenstein.de/wanderplan/2024#Vogelstimmen" target=_blank> Vogelstimmen Wanderung</a></li>
            <li>06.10.2024 10:00 Uhr: <a href="https://pwv-hauenstein.de/wanderplan/2024#Baum des Jahres" target=_blank> Baum des Jahres</a></li>  
          </ul>

          <h5>Möglichkeiten zum Mitwirken im Naturschutz</h5>
              <ul>
               <li>Nisthilfen bauen und pflegen</li>
               <li>Insektenhotels fachkundig anfertigen</li>
               <li>Biotope anlegen</li>
               <li>Müllsammelaktion im Wald</li>
               <li>... und mehr. Sie wollen sich gerne mit Ihren eigene Ideen einbringen? Werden Sie Mitglied.</li> 
              </ul>

              <a href="assets/imgs/UeberUns/MGAntrag2021.pdf">Mitgliedsantrag</a>
              
              (PDF) bitte ausdrucken, ausfüllen und mit der guten alten Post senden an:
              <p>PWV-Hauenstein<br>
              c/o Raymund Burkhard<br>
              Bahnhofstr. 37<br>
              76846 Hauenstein
              </p>
          </div>          
        </div>
    </div>
  
     <div class="card col-lg-6 col-sm-12" id= "Umweltsünden">
      <div class="card-body">
              <h5 class="card-title">Umweltverschmutzung</h5>
        <div class="card-text">     

          <h5>Wandern und Schutz der Natur</h5>
          <p>Wanderfreundinnen und Wanderfreunde erfreuen sich der Natur, 
              in der sie wandern und Erholung finden. Sie schützen und erhalten die Natur. 
              Für unsere Vereinsarbeit freuen wir uns über Mitstreiterinnen und Mitstreiter, 
              die gerne aktiv im Sinne der Natur mitmachen. Gerne auch mit eigenen Ideen und Projekten.<br> 
              Menschen, die den einzigartigen Pfälzerwald und darüber hinaus die 
              Umwelt verschandeln und verdrecken, bleiben besser zu Hause.
          </p> 

          <h5>Hinterlassenschaften</h5>
              <p>Für uns völlig unverständlich, dass Müll jeglicher Art
                in der Umwelt hinterlassen wird.
              </p>
              <p>Müll im Pfälzerwald. Hier ein Teil eines Staubsaugers.
              </p>
              <p><img src="assets/imgs/Umwelt/Staubsauger600x200.jpg" class="card-img" alt="Staubsaugerteil"><br>
              </p>
              <p><a data-toggle="collapse" href="#collapse1" style="color:black">Weitere Hinterlassenschaften   <fa-icon [icon]="faAngleDown">
                </fa-icon></a>    
              </p>            
 
            <div class="collapse" id="collapse1">
              <img src="assets/imgs/Umwelt/Staubsauger2_600x200.jpg" class="card-img" alt="Staubsauger"><br>
              <p>Staubsauger im Wald, nicht zu glauben aber wahr.
              </p>                   
              <img src="assets/imgs/Umwelt/Muell_2_600x200.jpg" class="card-img" alt="Papiertaschentuch"><br>
              <p>… Papiertaschentücher findet man zuHaufEn. Sie brauchen mehrere Jahre bis sie verrotten.
              </p>
              <img src="assets/imgs/Umwelt/Muell_10_600x200.jpg" class="card-img" alt="Babywindel"><br>
              <p>… eine Babywindel. Bleibt nur zu wünschen übrig, dass diese Eltern ihren Nachwuchs besser erziehen, 
                als sie offensichtlich selbst erzogen wurden.
              </p>
              <img src="assets/imgs/Umwelt/Muell_9_600x200.jpg" class="card-img" alt="Hundkot im Plastikbeutel"><br>
              <p>… Hundebesitzer, die die Hinterlassenschaften ihres bestens 4-Beinigen Freundes so hinterlassen, 
                  wollen die schöne Natur ganz offensichtlich nicht genießen.
              </p>
              <img src="assets/imgs/Umwelt/Muell_8_600x200.jpg" class="card-img" alt="Verpackungsmüll"><br>
              <p>… bitte "kein Bit"
              </p>
              <img src="assets/imgs/Umwelt/Muell_7_600x200.jpg" class="card-img" alt="Getränkedose"><br>
              <p>… du hast die volle Dose (oder auch Flasche) in den Wald rein getragen. 
                  Das Leergut ist leichter – warum nimmst du es nicht wieder mit?
              </p>    
              
              <p>Diverse Folien-Verpackungen im Wald und Gebüsch:
              </p>
              <p><img src="assets/imgs/Umwelt/Muell_6_600x200.jpg" class="card-img" alt="Folien-Verpackung"><br>
              </p>
              <p><img src="assets/imgs/Umwelt/Muell_5_600x200.jpg" class="card-img" alt="Folien-Verpackung"><br>
              </p>
              <p><img src="assets/imgs/Umwelt/Muell_4_600x200.jpg" class="card-img" alt="Folien-Verpackung"><br>
              </p>
              <img src="assets/imgs/Umwelt/Muell_3_600x200.jpg" class="card-img" alt="Folien-Verpackung"><br>
            </div>    
         </div>
      </div>
    </div>
  </div>
</div>

