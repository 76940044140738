import { map } from '../common/map.js';
import { nullTag } from '../common/null.js';
import { seq } from '../common/seq.js';
import { string } from '../common/string.js';
import { binary } from './binary.js';
import { trueTag, falseTag } from './bool.js';
import { floatNaN, floatExp, float } from './float.js';
import { intBin, intOct, int, intHex } from './int.js';
import { merge } from './merge.js';
import { omap } from './omap.js';
import { pairs } from './pairs.js';
import { set } from './set.js';
import { intTime, floatTime, timestamp } from './timestamp.js';
const schema = [map, seq, string, nullTag, trueTag, falseTag, intBin, intOct, int, intHex, floatNaN, floatExp, float, binary, merge, omap, pairs, set, intTime, floatTime, timestamp];
export { schema };