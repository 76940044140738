<nav class="navbar navbar-expand-lg navbar-light bg-light m-3" id="navbar">
  <a class="navbar-brand" routerLink="home" routerLinkActive="active">
    <img src="assets/imgs/logo_pwv.png" alt="neues Logo des PWV" style="width: auto; height: 80px;">
  </a>
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse me-auto" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="home" routerLinkActive="active">Home</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" role="button" id="WanderplanDropdown" data-bs-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Wanderungen
        </a>

        <ul class="dropdown-menu" aria-labelledby="WanderplanDropdown">
          <li><a class="nav-link" routerLink="wanderplan" routerLinkActive="active">Wanderplan</a></li>
          <li *ngFor="let year of years"><a class="nav-link" routerLink="wanderplan/{{year}}"
              routerLinkActive="active">Wanderplan {{year}}</a></li>
          <li><a class="nav-link" routerLink="senioren" routerLinkActive="active">Senioren</a></li>
          <li *ngFor="let year of years"><a class="nav-link" routerLink="senioren/{{year}}"
              routerLinkActive="active">Senioren {{year}}</a></li>
        </ul>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="wanderheim" routerLinkActive="active">Wanderheim</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="about" routerLinkActive="active">Über uns</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="umweltschutz" routerLinkActive="active">Umweltschutz</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="jungeFamilie" routerLinkActive="active">Junge Familie</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="kontakt" routerLinkActive="active">Kontakt</a>
      </li>

    </ul>

    <div class="ms-auto">
      <a class="m-1" target="_blank" href="https://www.instagram.com/pwvhauenstein/"><fa-icon [icon]="faInstagram"
          size="4x"></fa-icon></a>
      <a class="m-1" target="_blank" href="https://www.facebook.com/pwvhauenstein/"><fa-icon [icon]="faFacebook"
          size="4x"></fa-icon></a>
    </div>
  </div>
</nav>