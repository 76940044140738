<nav class="navbar navbar-expand-lg navbar-light bg-light mb-3" id="navbar">
    <a class="navbar-brand" routerLink="home" routerLinkActive="active">
      <img src="assets/imgs/logo_pwv.png" alt="neues Logo des PWV" style="width: auto; height: 80px;">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="home" routerLinkActive="active">Home</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" id="WanderplanDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Wanderungen
          </a>

          <div class="dropdown-menu" aria-labelledby="WanderplanDropdown">
            <a class="nav-link" routerLink="wanderplan" routerLinkActive="active">Wanderplan</a>
            <a class="nav-link" routerLink="senioren" routerLinkActive="active">Senioren</a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="wanderheim" routerLinkActive="active">Wanderheim</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="about" routerLinkActive="active">Über uns</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="umweltschutz" routerLinkActive="active">Umweltschutz</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="jungeFamilie" routerLinkActive="active">Junge Familie</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="kontakt" routerLinkActive="active">Kontakt</a>
        </li>

      </ul>
      <a target="_blank" href="https://www.instagram.com/pwvhauenstein/"><fa-icon [icon]="faInstagram" size="4x"></fa-icon></a>
      <a target="_blank" href="https://www.facebook.com/pwvhauenstein/"><fa-icon [icon]="faFacebook" size="4x"></fa-icon></a>
    </div>
  </nav>
