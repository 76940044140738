<div [id]="data.cardId">
    <div class="card">
        <img *ngIf="data.cardImagePath && data.cardImageAltText" class="card-img-top" [src]="data.cardImagePath" [alt]="data.cardImageAltText">
        <div class="card-body">
            <h5 class="card-title" [innerHTML]="data.title"></h5>
            <div class="card-subtitle">
                <ul *ngIf="data.startTime && data.locationUrl && data.locationName && data.duration && data.leader" style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>{{data.startTime}}
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a [href]="data.locationUrl" target="_blank">{{data.locationName}}</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>{{data.duration}}
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>{{data.leader}}
                    </li>
                </ul>
                <a *ngIf="data.content" data-bs-toggle="collapse" href="#collapse{{data.cardId}}" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div *ngIf="data.content" class="collapse" id="collapse{{data.cardId}}">
                <div class="card-text" [innerHTML] = "data.content">
                </div>
            </div>
            <div *ngIf="data.contentWithoutCollapse" class="card-text" [innerHTML] = "data.contentWithoutCollapse">
            </div>
        </div>
    </div>
</div>