import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ContactForm, Matters } from 'src/app/models/contactForm'
import { ContactService } from 'src/app/services/contact.service'

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css'],
    standalone: false
})
export class ContactComponent implements OnInit, OnDestroy {

  subscription: Subscription

  contactFormGroup = this.fb.group({
    name: [''],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    matter: ['', Validators.required],
    message: ['', Validators.required],
    newsletter: [''],
    phoneNumber: [''],
    readPrivacy: ['',Validators.requiredTrue]
  });

  fastTimeoutInMs = 2000;
  submitted = false;
  Matters = Matters;
  hideForm = false;
  hideThankYou = true;
  hideFailedText = true;
  createdOn = new Date();

  constructor(
    private fb: UntypedFormBuilder,
    private contactService: ContactService
    ) { }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  onSubmit() {
    this.submitted = true;
    this.hideFailedText = true;
    if(this.contactFormGroup.valid){
      let contactForm = this.parseContactForm(this.contactFormGroup.value)
      this.sendContactForm(contactForm);
    }
  }

  parseContactForm(formToParse) : ContactForm
  {
    let newsletter = formToParse["newsletter"] === '' ? false : formToParse["newsletter"];

    let phoneNumber = formToParse["phoneNumber"] === '' ? null : formToParse["phoneNumber"];

    const timeDiff = new Date().getTime() - this.createdOn.getTime()
    let name =  timeDiff < this.fastTimeoutInMs ? 'fast' : formToParse["name"];

    return new ContactForm(
      name,
      formToParse["firstName"],
      formToParse["lastName"],
      formToParse["email"],
      +formToParse["matter"],
      formToParse["message"],
      newsletter,
      phoneNumber
    )     
  }

  sendContactForm(contactForm: ContactForm)
  {
    this.subscription = this.contactService.sendContactForm(contactForm).subscribe({
      next: data => { this.handleNext(data) },
      error: error => { this.handleError(error) },      
    })
  }

  handleError(error)
  {
    this.hideFailedText = false;
  }

  handleNext(data)
  {
      this.hideForm = true;
      this.hideThankYou = false;
  }

  ngOnInit(): void {
  }

}
