import { Component, OnInit } from '@angular/core';
import { faMapMarked, faUser, faClock, faHourglass, faAngleUp, faAngleDown, faHiking } from '@fortawesome/free-solid-svg-icons'
@Component({
  selector: 'app-junge-familie',
  templateUrl: './junge-familie.component.html',
  styleUrls: ['./junge-familie.component.css']
})
export class JungeFamilieComponent implements OnInit {

  constructor() { }
  faMapMarked = faMapMarked
  faUser = faUser
  faClock = faClock
  faHourglass = faHourglass
  faAngleUp = faAngleUp
  faAngleDown = faAngleDown
  faHiking = faHiking
  ngOnInit(): void {
  }

}