<div class="container-fluid">
<div class="row mb-3">

    <div class="col-lg-4 col-sm-12"id="Senioren23.1">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/WP_Senioren/23Senioren1.jpg" alt="Seniorenwart Hermann Landau" title="Seniorenwart Hermann Landau">
        <div class="card-body">
            <div class="card-text">   
                   Danke an unseren Seniorenwart Hermann.<br>
Nach langen Jahren hat Hermann sein Tätigkeit als Seniorenwart eingestellt.
Für das hohe Engagement in all den Jahren, danken wir ihm von ganzen Herzen
und wünschem ihm alles erdenklich Gute für seinen weiteren Lebensweg. 
Hermann fühlt sich natürlich auch weiterhin unserem Verein verbunden und hilft mit.

            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Senioren23.2">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/WP_Senioren/23Senioren2.jpg" alt="Die Senioren im Hochgebirge" title="Die Senioren im Hochgebirge">
        <div class="card-body">
            <div class="card-text">   
                   Die Wanderungen werden immer seniorengerecht durchgeführt. Auch wenn wir, wie hier auf dem Foto, im Hochgebirge sind.
Die Wander-Events werden für fitte und auch für nicht mehr ganz so mobile
Wanderfreundinnen und Wanderfreunde angeboten. Dabeisein ist alles.
Ein Päuschen geht immer.

            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Senioren23.3">
    <div class="card">
        <img class="card-img-top" src="assets/imgs/wanderplan/WP_Senioren/23Senioren3.jpg" alt="Senioren im Schoppenbähnel" title="Senioren im Schoppenbähnel">
        <div class="card-body">
            <div class="card-text">   
                   Geselligkeit und pflegen von sozialen Kontakten werden „großgeschrieben“.
Nach jeder Wanderungen findet ein gemeinsamer Abschluss bei gemütlichem Zusammensein statt.
Ausflüge mit dem Bus, im "Schoppenbähnel", Picknick, „Grummbeerebroore“ und
weitere Veranstaltungen finden immer großen Anklang.

            </div>
        </div>
    </div>
</div>
    </div>
<div class="row mb-3">

    <div class="col-lg-4 col-sm-12"id="Neujahr">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Eröffnungswanderung mit Umtrunk</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>11.01.2024 14:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Wanderparkplatz+%22Farrenwiese%22/@49.1863269,7.8402945,16.13z/data=!4m5!3m4!1s0x47966731640d898d:0x8e924808627718a8!8m2!3d49.185874!4d7.8427171" target="_blank">Farrenwiese</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Hermann Landau
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse3" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse3">
                <div class="card-text">   
                   Ganz in alter Tradition wandern wir im Stephanstal. Zum Abschluss, mit gemütlichem Beisammensein, finden wir 
bei unseren Vereinsfreunden vom TVH ein.
Wegstrecke: Rundwanderweg im Stephanstal (Queichtal). 

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Neufeld">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Im Neufeld</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>25.01.2024 14:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Netto+Marken-Discount/@49.1962843,7.837743,17.29z/data=!4m5!3m4!1s0x47966723b390155d:0x924a37cf98fac24f!8m2!3d49.1964188!4d7.8397726" target="_blank">Netto-Markt</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Josef Braun
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse4" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse4">
                <div class="card-text">   
                   Wegstrecke: Netto-Markt - Tankstelle - Gewerbegebiet Neufeld - Neding<br>
Heute findet unser gemeinsamer Abschluss bei "d´Lore" statt.

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Kreuzel">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Unter dem Kreuzel</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>08.02.2024 14:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Wasgaufreibad+Hauenstein/@49.1828914,7.8390922,15.18z/data=!4m6!3m5!1s0x47966736107c5729:0xcfdee1590f46b9e1!8m2!3d49.1824573!4d7.8509416!16s%2Fg%2F11bt_jrnhv?entry=ttu" target="_blank">Wasgau-Freibad</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Walter Meyer
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse5" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse5">
                <div class="card-text">   
                   Wegstrecke: Panoramaweg - Weimersberg<br>
Der Abschluss unserer heutigen Wanderung findet im Dorfstübel statt.

                </div>
            </div>
        </div>
    </div>
</div>
    </div>
<div class="row mb-3">

    <div class="col-lg-4 col-sm-12"id="Rauschloch">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Rauschloch</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>22.02.2024 14:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Bergstra%C3%9Fe+%26+Waldenburgerstra%C3%9Fe,+76846+Hauenstein/@49.1952591,7.833422,17.42z/data=!4m5!3m4!1s0x47966726bb1c4329:0x5daa2d79a986c22a!8m2!3d49.1954941!4d7.8338503" target="_blank">Friedenskirche</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Günther Auerhammer
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse6" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse6">
                <div class="card-text">   
                   Wegstrecke: Vier Buchen - Henkler-Brünnel - Rauschloch.<br>
Der Abschluss unserer heutigen Wanderung findet bei unseren Vereinfreunden beim TVH statt.

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Mischberg">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Mischberg-Tour</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>07.03.2024 14:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Wasgauschule+Realschule+Plus+Hauenstein/@49.1968211,7.8508719,17.29z/data=!3m1!5s0x4796673f1257835b:0x1a9680f62c384faf!4m5!3m4!1s0x410b81fa214c9141:0x274623f5adbfa874!8m2!3d49.1969889!4d7.8523968" target="_blank">Wasgauschule</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Josef Braun
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse7" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse7">
                <div class="card-text">   
                   Wegstrecke: Wasgauschule - Seniorenheim - um den Neding<br>
Abschluss: bei "d´Lore".

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Osterwanderung">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Osterwanderung</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>21.03.2024 14:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Wasgauschule+Realschule+Plus+Hauenstein/@49.1968211,7.8508719,17.29z/data=!3m1!5s0x4796673f1257835b:0x1a9680f62c384faf!4m5!3m4!1s0x410b81fa214c9141:0x274623f5adbfa874!8m2!3d49.1969889!4d7.8523968" target="_blank">Wasgauschule</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Hermann Landau
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse8" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse8">
                <div class="card-text">   
                   Wegstrecke: Wasgauschule - Ziegelhütte - Friedhof - Katharinenkapelle.<br>
Abschluss: beim "Wölfel" in der Zwickerstubb.

                </div>
            </div>
        </div>
    </div>
</div>
    </div>
<div class="row mb-3">

    <div class="col-lg-4 col-sm-12"id="Felder">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Über die Felder</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>04.04.2024 14:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Parkplatz+Georg+Kratz/@49.1930854,7.8546273,16.82z/data=!4m10!1m2!2m1!1sParkplatz+Landauer-Str,+Hauenstein!3m6!1s0x47966746e35bcf5b:0x258171e8eb94e558!8m2!3d49.1928947!4d7.8566186!15sCiJQYXJrcGxhdHogTGFuZGF1ZXItU3RyLCBIYXVlbnN0ZWlukgELcGFya2luZ19sb3TgAQA!16s%2Fg%2F11f53pg85w?entry=ttu" target="_blank">Friedhof-Parkplatz Landauer-Str.</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Hermann Jost
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse9" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse9">
                <div class="card-text">   
                   Wegstrecke: Friedhof - über die Felder - Ankers-Kreuz - Dorfstübel.<br>
Abschluss: im Dorfstübel

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Wilgartswiesen">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Iwwer de B10</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>18.04.2024 14:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="null" target="_blank">Netto-Markt</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Kunibert Grünenwald
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse10" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse10">
                <div class="card-text">   
                   https://www.google.de/maps/place/Netto+Marken-Discount/@49.1962843,7.837743,17.29z/data=!4m5!3m4!1s0x47966723b390155d:0x924a37cf98fac24f!8m2!3d49.1964188!4d7.8397726
                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Hütte">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Uff d´Hitt</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>08.05.2024 13:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Wasgaufreibad+Hauenstein/@49.1878595,7.847267,15.25z/data=!4m5!3m4!1s0x47966736107c5729:0xcfdee1590f46b9e1!8m2!3d49.1824573!4d7.8509416" target="_blank">1. Freischwimmbad 2. Schuhe-Mühlenz s.u.</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Walter Meyer
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse11" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse11">
                <div class="card-text">   
                   2. Treffpunkt bei: <a href="https://www.google.de/maps/place/Dahner+Stra%C3%9Fe+%26+Trifelsstra%C3%9Fe,+76846+Hauenstein/@49.1903033,7.8459805,17.87z/data=!4m5!3m4!1s0x4796673759a9a6ef:0xfb9945a3e6492865!8m2!3d49.1901341!4d7.8469471"target=_blank>Schuhe-Mühlenz</a><br>
Wanderstrecke:  Schwimmbad - Rentnerweg - Wanderheim.<br>
Wir freuen uns auf das Zusammensein in unserer "Hitt".

                </div>
            </div>
        </div>
    </div>
</div>
    </div>
<div class="row mb-3">

    <div class="col-lg-4 col-sm-12"id="Clausental">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Wanderfahrt ins Clausental</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>16.05.2024 13:30
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/search/Bushaltestellen,+in+HAuenstein/@49.1969054,7.8408027,15.71z" target="_blank">Bushaltestellen Marktplatz. Felsentor. Kleiner Muck.</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>4.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Walter Meyer
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse12" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse12">
                <div class="card-text">   
                   Fahrt nach Rodalben. Wanderung: Clausental - Felsenwanderweg.  
Abschluss im Hilschberghaus.

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Streich">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Durch die Streich</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>29.05.2024 14:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Landauer+Stra%C3%9Fe+%26+Speyerstra%C3%9Fe,+76846+Hauenstein/@49.1961883,7.85474,16.77z/data=!4m6!3m5!1s0x479667413ff11bc9:0x175050b8a0611c32!8m2!3d49.1958369!4d7.8586254!16s%2Fg%2F11f337n37r?entry=ttu" target="_blank">Ziegelhütte</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Hermann Landau
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse13" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse13">
                <div class="card-text">   
                   Wegstrecke: Streich Richtung Spirkelbach - Radweg - Ankers-Kreuz - Zwickerstubb. <br>
Der Abschluss unserer heutigen Wanderung findet in der Zwickerstubb beim "Wölfel" statt.

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Philosophenweg">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Philosophenweg</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>13.06.2024 14:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Wasgaufreibad+Hauenstein/@49.1828914,7.8390922,15.18z/data=!4m6!3m5!1s0x47966736107c5729:0xcfdee1590f46b9e1!8m2!3d49.1824573!4d7.8509416!16s%2Fg%2F11bt_jrnhv?entry=ttu" target="_blank">Wasgau-Freibad</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Brigitte Keiner
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse14" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse14">
                <div class="card-text">   
                   Wegstrecke: Schwimmbad - Philosphenweg.<br>
Der Abschluss unserer heutigen Wanderung findet im Dorfstübel statt.

                </div>
            </div>
        </div>
    </div>
</div>
    </div>
<div class="row mb-3">

    <div class="col-lg-4 col-sm-12"id="Picknick">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Picknick</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>27.06.2024 14.00 Uhr
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Hauenstein,+Bouleplatz/@49.1860408,7.8297638,15z/data=!4m9!1m2!2m1!1sGrillplatz+76846+Hauenstein!3m5!1s0x479667318ca372df:0x9665e33973877aa9!8m2!3d49.186041!4d7.840152!16s%2Fg%2F11csq25pf1?entry=ttu" target="_blank">Boulefreunde</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>3.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>alle finden ihren Weg
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse15" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse15">
                <div class="card-text">   
                   Jeder findet seinen Weg zum Gaumengenuss bei unseren Boul-Vereinsfreunden. Bringt gute Laune und schönes Wetter mit. 
Wir freuen uns auf einen schönen Nachmittag.

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Kneispermühle">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Wanderfahrt zur Kneispermühle</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>11.07.2023 13:30
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/search/Bushaltestellen,+in+HAuenstein/@49.1969054,7.8408027,15.71z" target="_blank">Bushaltestellen Marktplatz. Felsentor. Kleiner Muck.</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>4.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Fred Keiner
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse16" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse16">
                <div class="card-text">   
                   Fahrt zur Kneispermühle. Kleine Wanderung mit anschließender Einkehr in der Kneispermühle.    

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Neding">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Um den Neding</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>25.07.2024 14:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Netto+Marken-Discount/@49.1962843,7.837743,17.29z/data=!4m5!3m4!1s0x47966723b390155d:0x924a37cf98fac24f!8m2!3d49.1964188!4d7.8397726" target="_blank">Netto-Markt</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Fred Keiner
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse17" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse17">
                <div class="card-text">   
                   Wir wandern auf der Sonnenseite des Mischbergs zum Neubaugebiet "Am Sonnenhang" - Prälat-Sommer-Str. - Im Tal - TVH.<br>
Abschluss bei unseren Vereinsfreunden vom TVH.

                </div>
            </div>
        </div>
    </div>
</div>
    </div>
<div class="row mb-3">

    <div class="col-lg-4 col-sm-12"id="Drei Buchen">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Wanderfahrt 'Drei Buchen'</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>08.08.2024 13:30
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/search/Bushaltestellen,+in+HAuenstein/@49.1969054,7.8408027,15.71z" target="_blank">Bushaltestellen Marktplatz. Felsentor. Kleiner Muck.</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>4.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Kunibert Grünewald
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse18" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse18">
                <div class="card-text">   
                   Fahrt zum Wanderheim "Drei Buchen". Wanderung zur Burgruine Meistersel.<br>
Abschluss: im Wanderheim Drei Buchen.

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Pitztal">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Wanderfahrt ins Pitztal</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>18.08.2024 07:00
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/search/Bushaltestellen,+in+HAuenstein/@49.1969054,7.8408027,15.71z" target="_blank">Bushaltestellen Marktplatz. Felsentor. Kleiner Muck.</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>Mehrtagestour
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Fred Keiner
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse19" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse19">
                <div class="card-text">   
                   Das Programm wird zeitnahe bekannt gegeben.
Verbindliche und frühzeitige Anmeldung bei Fred Keiner.

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Weinland">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Wanderfahrt ins Weinland</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>05.09.2024 13:30
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/search/Bushaltestellen,+in+HAuenstein/@49.1969054,7.8408027,15.71z" target="_blank">Bushaltestellen Marktplatz. Felsentor. Kleiner Muck.</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>4.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Hermann Landau
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse20" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse20">
                <div class="card-text">   
                   Route: im Weinland unterwegs.<br>
Abschluss: in einer Straußwirtschaft.

                </div>
            </div>
        </div>
    </div>
</div>
    </div>
<div class="row mb-3">

    <div class="col-lg-4 col-sm-12"id="Grummbeerebroore">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Grummbeerebroore</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>14.09.2024 12.00 Uhr
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Jugendzeltplatz-Hauenstein/@49.1869991,7.8576334,15.83z/data=!4m5!3m4!1s0x4796674629f6f6a9:0xa037f9dbbc592355!8m2!3d49.1844835!4d7.8622594" target="_blank">"Im Stopper" (Jugendzeltplatz)</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>offenes Ende
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Vorstand
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse21" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse21">
                <div class="card-text">   
                   Wir halten die Tradition hoch. Geselliges Beisammensein mit pfälzischen Spezialitäten.
Route: "s´Dorf naus, an de Ranch vebei in de Stopper".
Geschirr etc. bitte mitbringen!
Tourenbeschreibung: Der Name ist Programm.
Der Ursprung des "Grummbeerebroore" ist aus geschichtlichen Erzählungen wie folgt bekannt: nach der Ernte wurde auf dem Kartoffelacker 
ein Feuer aus Kartoffelkraut entzündet und darin die Kartoffeln gegart. Zum Anderen hatten früher die Waldarbeiter aus frisch geschlagenem 
Buchenholz ein Feuer entzündet und in der daraus gewonnen Glut die Kartoffeln zum Mittagessen zubereitet. Daraus entstand die heutige Tradition, die insbesondere in
Hauenstein von vielen Vereinen gepflegt wird.

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Stephanstal">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Durch das Stephanstal (Queichtal)</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>19.09.2024 14.00 Uhr
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Wanderparkplatz+%22Farrenwiese%22/@49.1846415,7.8375815,15.64z/data=!4m6!3m5!1s0x47966731640d898d:0x8e924808627718a8!8m2!3d49.185874!4d7.8427171!16s%2Fg%2F11fx_0vb_s?entry=ttu" target="_blank">Farrenwiese</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Kunibert Grünenwald
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse22" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse22">
                <div class="card-text">   
                   Wegstrecke: Farrenwiese -Rundweg im Stephanstal (Queichtal).<br>
Nach der Wanderung treffen wir uns in der Paddelweiherhütte.

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Herbstwald">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Durch den herbstlichen Wald</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>02.10.2024 14.00 Uhr
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Bergstra%C3%9Fe+%26+Waldenburgerstra%C3%9Fe,+76846+Hauenstein/@49.1952591,7.833422,17.42z/data=!4m5!3m4!1s0x47966726bb1c4329:0x5daa2d79a986c22a!8m2!3d49.1954941!4d7.8338503" target="_blank">Friedenskirche</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Fred Keiner
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse23" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse23">
                <div class="card-text">   
                   Route: Friedenskirche - Henkler-Brünnel - Vier Buchen - .<br>
Abschluss: bei unseren Vereinfreunden vom TVH

                </div>
            </div>
        </div>
    </div>
</div>
    </div>
<div class="row mb-3">

    <div class="col-lg-4 col-sm-12"id="Beckenhof">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Wanderfahrt zum Beckenhof</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>17.10.2024 13:30
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/search/Bushaltestellen,+in+HAuenstein/@49.1969054,7.8408027,15.71z" target="_blank">Bushaltestellen Marktplatz. Felsentor. Kleiner Muck.</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>4.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Kurt Lenhardt
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse24" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse24">
                <div class="card-text">   
                   Route: Fahrt zum Beckenhof - Felsentor - Platte<br>
Abschluss: im Beckenhof 

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Weidentälchen">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Durchs Weidentälchen</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>31.10.2024 14.00 Uhr
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Wasgaufreibad+Hauenstein/@49.1828914,7.8390922,15.18z/data=!4m6!3m5!1s0x47966736107c5729:0xcfdee1590f46b9e1!8m2!3d49.1824573!4d7.8509416!16s%2Fg%2F11bt_jrnhv?entry=ttu" target="_blank">Wasgau-Freibad</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Georg Burkhart
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse25" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse25">
                <div class="card-text">   
                   Route: Langer Dümpel - Weidentälchen - Schwimmbad.<br>
Abschluss: heute findet unser Abschluss im Dorfstübel statt.

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Ehrenfriedhof">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Wanderfahrt zum Ehrenfriedhof</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>14.11.2024 13:30
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/search/Bushaltestellen,+in+HAuenstein/@49.1969054,7.8408027,15.71z" target="_blank">Bushaltestellen Marktplatz. Felsentor. Kleiner Muck.</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>4.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Hermann Landau
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse26" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse26">
                <div class="card-text">   
                   Route: Rodalben - Ehrenfriedhof.<br> Wir gedenken der Opfer aus den Weltkriegen.
Abschluss: im Birkwieserhof

                </div>
            </div>
        </div>
    </div>
</div>
    </div>
<div class="row mb-3">

    <div class="col-lg-4 col-sm-12"id="Dorf">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Durch's Dorf</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>28.11.2024 14.00 Uhr
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Wasgauschule+Realschule+Plus+Hauenstein/@49.1968211,7.8508719,17.29z/data=!3m1!5s0x4796673f1257835b:0x1a9680f62c384faf!4m5!3m4!1s0x410b81fa214c9141:0x274623f5adbfa874!8m2!3d49.1969889!4d7.8523968" target="_blank">Friedhof</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>2.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Gerhard Morio
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse27" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse27">
                <div class="card-text">   
                   Route: Friedhof - Katharinenkapelle - Marktplatz.<br>
Abschluss: beim Wölfel in der Zwickerstubb

                </div>
            </div>
        </div>
    </div>
</div>
    
    <div class="col-lg-4 col-sm-12"id="Weihnachtsfeier">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Weihnachtsfeier</h5>
            <div class="card-subtitle">
                <ul style="list-style-type:none;">
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faClock"></fa-icon>12.12.2024 14.00 Uhr
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faMapMarked"></fa-icon><a href="https://www.google.de/maps/place/Turnverein+1901+e.V.+Hauenstein/@49.1938971,7.8411562,16.42z/data=!4m5!3m4!1s0x4796673b10f656f7:0xb9482305e633f618!8m2!3d49.1939044!4d7.8428427" target="_blank">TVH Gaststätte</a>
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faHourglass"></fa-icon>4.0 Stunden
                    </li>
                    <li>
                        <fa-icon style="margin-right: 5px;" [fixedWidth]="true" [icon]="faUser"></fa-icon>Fred Keiner (Anmeldung)
                    </li>
                </ul>
                <a data-toggle="collapse" href="#collapse28" style="color:black"><fa-icon [icon]="faAngleDown"></fa-icon>
                </a>
            </div>
            <div class="collapse" id="collapse28">
                <div class="card-text">   
                   Anmeldung bis 01.12.2024 bei Fred Keiner Tel.: 06392 1825 <br>
Alle Wege führen zu unseren TVH-Vereinsfreunden. Wir freuen uns auf die Adventszeit. 

                </div>
            </div>
        </div>
    </div>
</div>
    </div>
</div>