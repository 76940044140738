import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing'
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { LegalComponent } from './components/legal/legal.component';
import { WanderplanComponent } from './components/wanderplan/wanderplan.component';
import { HuetteComponent } from './components/huette/huette.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactComponent } from './components/contact/contact.component';
import { JungeFamilieComponent } from './components/junge-familie/junge-familie.component';
import { EnvironmentComponent } from './components/environment/environment.component';
import { WanderplanCardComponent } from './components/wanderplan/wanderplan-card/wanderplan-card.component';

@NgModule({ declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        PrivacyComponent,
        LegalComponent,
        HuetteComponent,
        ContactComponent,
        AboutUsComponent,
        JungeFamilieComponent,
        EnvironmentComponent,
        WanderplanComponent,
        WanderplanCardComponent
    ],
    bootstrap: [AppComponent],
    imports: [BrowserModule,
        FontAwesomeModule,
        AppRoutingModule,
        ReactiveFormsModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
