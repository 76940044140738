import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-legal',
    templateUrl: './legal.component.html',
    styleUrls: ['./legal.component.css'],
    standalone: false
})
export class LegalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
