import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing'
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { LegalComponent } from './components/legal/legal.component';
import { Wanderplan2024Component } from './components/wanderplan/2024/wanderplan.component';
import { Senioren2024Component } from './components/senioren/2024/senioren.component';
import { HuetteComponent } from './components/huette/huette.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactComponent } from './components/contact/contact.component';
import { JungeFamilieComponent } from './components/junge-familie/junge-familie.component';
import { EnvironmentComponent } from './components/environment/environment.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    PrivacyComponent,
    LegalComponent,
    Senioren2024Component,
    HuetteComponent,
    ContactComponent,
    AboutUsComponent,
    JungeFamilieComponent,
    EnvironmentComponent,
    Wanderplan2024Component
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
