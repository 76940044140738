import { Injectable } from '@angular/core';
import { WanderplanCardData } from './wanderplan-card.component';
import { parse } from 'yaml'

@Injectable({
  providedIn: 'root'
})
export class WanderplanCardYamlParserService {

  constructor(
  ) {}
  
  public parseYaml(yaml: string) : WanderplanCardData[]
  {
    const parsedYaml = parse(yaml);
    return parsedYaml.map((data: YamlContent) => {
        return {
            cardId: data.wanderungsId,
            title: data.titel,
            content: data.beschreibung,
            cardImagePath: data.titelBildLink,
            cardImageAltText: data.titelBildAltText,
            startTime: data.startZeit,
            locationUrl: data.startPunktLink,
            locationName: data.startPunkt,
            duration: data.dauer,
            leader: data.leitung,
            contentWithoutCollapse: data.beschreibungOhneCollapse
    }});
  }
}

class YamlContent {
  wanderungsId: string;
  titel: string;
  beschreibungOhneCollapse?: string = null;
  beschreibung?: string = null;
  titelBildLink?: string = null;
  titelBildAltText?: string = null;
  startZeit?: string = null;
  startPunkt?: string = null;
  startPunktLink?: string = null;
  dauer?: string = null;
  leitung?: string = null;
}
