import { Component, OnInit } from '@angular/core';
import { faMapMarked, faUser, faClock, faHourglass, faAngleUp, faAngleDown, faHiking } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-wanderplan-2024',
  templateUrl: './wanderplan.component.html',
})
export class Wanderplan2024Component implements OnInit {

  faMapMarked = faMapMarked
  faUser = faUser
  faClock = faClock
  faHourglass = faHourglass
  faAngleUp = faAngleUp
  faAngleDown = faAngleDown
  faHiking  = faHiking
  constructor() { }

  ngOnInit(): void {

  }
}
