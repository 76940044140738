import { Component, OnDestroy, OnInit } from '@angular/core';

import { WanderplanCardData } from './wanderplan-card/wanderplan-card.component';
import { WanderplanCardYamlParserService } from './wanderplan-card/wanderplan-card-yaml-parser';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ActivatedRoute, UrlSegment } from '@angular/router';

@Component({
    selector: 'app-wanderplan-2024',
    templateUrl: './wanderplan.component.html',
    standalone: false
})

export class WanderplanComponent implements OnInit, OnDestroy {

  public wanderplanCards: WanderplanCardData[] = [];
  private wanderplanSubscription: Subscription;
  private urlSubscription: Subscription;
  constructor(
    private yamlParser: WanderplanCardYamlParserService,
    private httpClient: HttpClient,
    private route: ActivatedRoute) { }
    
    ngOnInit(): void {
      this.urlSubscription = this.route.url.subscribe(this.onUrlChange.bind(this));
    }
    
    ngOnDestroy(): void {
      this.urlSubscription?.unsubscribe();
      this.wanderplanSubscription?.unsubscribe();
    }

    private onUrlChange(url: UrlSegment[]): void {
      const yearToFetch = url.length == 2 ? url[1].path : new Date().getFullYear();;
      const yamlToFetch = `${url[0].path}.${yearToFetch}.yml`;
      this.wanderplanSubscription?.unsubscribe();
      this.wanderplanSubscription = this.httpClient.get(`assets/wanderplan/${yamlToFetch}`, {responseType: 'text'})
        .subscribe(data => this.wanderplanCards = this.yamlParser.parseYaml(data));
    }
}
