<div class="container-fluid">
  <div class="row">
    <div class="card col-lg-6 col-sm-12">
      <div class="card-body">
        <h5 class="card-title">Pfälzerwald-Verein Hauenstein</h5>
        <div class="card-text">
          <h5>Gründung am 08. März 1913</h5>
          <p>Unsere Ortsgruppe wurde von 21 Bürgern im Gasthaus „Pfälzerhof“ gegründet.
            Einige Tage später wurde der erste Wanderplan festgelegt.
            Die erste Tour führte zum Lindelbrunn, Berwartstein und Drachenfels, Ziele die von besonderer
            heimatkundlicher Bedeutung waren. Seit nunmehr über 100 Jahre engagiert sich der PWV gemeinnützig.
            Sie wollen mehr über 100 Jahre PWV-Hauenstein erfahren? Erwerben Sie dazu unsere Chronik.
            E-Mail: <a _ngcontent-qpp-c12="" href="mailto:info@pwv-hauenstein.de">info&#64;pwv-hauenstein.de</a>
          </p>
          <div class="accordion accordion-flush" id="gruendungsprotokoll">
            <div class="accordion-item">
              <h2 class="accordion-header" id="protokoll">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#protokollCollapse" aria-expanded="false" aria-controls="protokollCollapse">
                  Das Gründungsprotokoll (Sütterlin)
                </button>
              </h2>
              <div id="protokollCollapse" class="accordion-collapse collapse" aria-labelledby="protokoll"
                data-bs-parent="#gruendungsprotokoll">
                <div class="accordion-body">
                  <img src="assets/imgs/UeberUns/Gruendungsprotokoll.jpg" class="card-img" alt="Gründungsprotokoll">
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="protokollUebersetzung">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#protokollUebersetzungCollapse" aria-expanded="false"
                  aria-controls="protokollUebersetzungCollapse">
                  Das Gründungsprotokoll (Übersetzung)
                </button>
              </h2>
              <div id="protokollUebersetzungCollapse" class="accordion-collapse collapse"
                aria-labelledby="protokollUebersetzung" data-bs-parent="#gruendungsprotokoll">
                <div class="accordion-body">
                  <img src="assets/imgs/UeberUns/GruendungsprotokollUebersetz.jpg" class="card-img"
                    alt="Übersetzung Gründungsprotokoll"><br>
                </div>
              </div>
            </div>
          </div>
          <h5>Vereinsarbeit</h5>
          Wir engagieren uns gemeinnützig und ehrenamtlich:
          <ul>
            <li>Markierung und Instandhaltung der Wanderwege</li>
            <li>Wanderungen, Aktionen und Veranstaltungen für Aktive, "Junge Familie" und Senioren </li>
            <li>Umwelt- und Naturschutz</li>
            <li>Unterhalten des Wanderheims "Dicke Eiche"</li>
            <li>... und mehr. Unterstützen Sie die gute Sache. Werden Sie Mitglied.</li>
          </ul>

          <h5>PWV-Hauptverein</h5>
          Unser <a target="_blank" href="http://www.pwv.de">PWV-Hauptverein</a>
          bietet ebenfalls viele Möglichkeiten der Mitwirkung:
          <ul>
            <li>Veranstaltungen aller Art</li>
            <li>Lehrgänge, Workshops u.v.m.</li>
          </ul>

          <h5>Eröffnung Wanderheim "Dicke Eiche"</h5>
          <p>Nachdem bereits viele Jahre zuvor die Erichtung eines Wanderheims in Erwägung gezogen wurde,
            war es 1971 soweit, dass mit dem Bau unserer Hütte "Dicke Eiche" begonnen wurde. Mit viel Herzblut
            wurden unzählige Stunden in Eigenleistung erbracht und 1974 war es endlich soweit: "d´Hitt" wurde eröffnet.
          </p>

          <p><img src="assets/imgs/UeberUns/1974Wanderheim.jpg" class="card-img" alt="Wanderheim Dicke Eiche 1974">
          </p>

          <p>Das Wanderheim "Dicke Eiche" im Jahre 1974
          </p>

          <h5>Kontakt/Vorstand</h5>
          <p>E-Mail: <a _ngcontent-qpp-c12="" href="mailto:info@pwv-hauenstein.de">info&#64;pwv-hauenstein.de</a></p>

          <div class="accordion accordion-flush" id="vorstandAccordion">
            <div class="accordion-item">
              <h2 class="accordion-header" id="vorstand">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#vorstandCollapse" aria-expanded="false" aria-controls="vorstandCollapse">
                  Der Vorstand
                </button>
              </h2>
              <div id="vorstandCollapse" class="accordion-collapse collapse" aria-labelledby="vorstand"
                data-bs-parent="#vorstandAccordion">
                <div class="accordion-body">
                  <h6>Vorstandsvorsitzender</h6>
                  <p>Johannes Scheib<br>
                    E-Mail: <a _ngcontent-qpp-c12=""
                      href="mailto:r.burkhard@pwv-hauenstein.de">j.scheib&#64;pwv-hauenstein.de</a><br>
                    Tel: 06392-3049
                  </p>

                  <h6>Vorstand Finanzen</h6>
                  <p>Markus Johann<br>
                    E-Mail: <a _ngcontent-qpp-c12=""
                      href="mailto:m.johann@pwv-hauenstein.de">m.johann&#64;pwv-hauenstein.de</a><br>
                    Tel.: 06392-3805
                  </p>

                  <h6>Vorstand Wandern/aktives Vereinsleben</h6>
                  <p>Michael Keiser<br>
                    E-Mail: <a _ngcontent-qpp-c12=""
                      href="mailto:m.keiser@pwv-hauenstein.de">m.keiser&#64;pwv-hauenstein.de</a><br>
                    Tel.: 06392-7394
                  </p>

                  <h6>Vorständin Natur-/Umweltschutz</h6>
                  <p>Vera Klemm<br>
                    E-Mail: <a _ngcontent-qpp-c12=""
                      href="mailto:v.klemm@pwv-hauenstein.de">v.klemm&#64;pwv-hauenstein.de</a><br>
                  </p>

                  <h6>Vorstand Hüttenbetrieb/Bauen/Liegenschaften</h6>
                  <p>Raymund Burkhard<br>
                    E-Mail: <a _ngcontent-qpp-c12=""
                      href="mailto:a.memmer@pwv-hauenstein.de">r.burkhard&#64;pwv-hauenstein.de</a><br>
                    Tel.: 06392-2857
                  </p>

                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>

    <div class="card col-lg-6 col-sm-12">
      <div class="card-body">
        <h5 class="card-title">Den Pfälzerwald-Verein unterstützen</h5>
        <div class="card-text">
          <h5>Mitglied werden</h5>
          <p>Unterstützen Sie uns aktiv oder selbstverständlich auch gerne passiv. Für "sage und schreibe" 19 €
            Jahresbeitrag
            unterstützen Sie die gute Sache. Und es kommt noch besser: die/der Partner*In des A-Mitgliedes bezahlt
            nur 8 € Jahresbeitrag und Kinder bis 18 Jahre sind beitragsfrei.
          </p>

          <ul>
            <li>A-Mitglied 19 € </li>
            <li>B-Mitglied (Partner*In) 8 €</li>
            <li>C-Mitglied (Jugendl. ab 14 Jahre) beitragsfrei bis 18 Jahre
            <li>Zweitmitglied (bereits Mitgl. einer anderen Ortsgruppe) 8 €</li>
          </ul>

          <a href="assets/imgs/UeberUns/MGAntrag2021.pdf">Mitgliedsantrag</a>

          (PDF) bitte ausdrucken, ausfüllen und mit der guten alten Post senden an:
          <p>PWV-Hauenstein<br>
            c/o Raymund Burkhard<br>
            Bahnhofstr. 37<br>
            76846 Hauenstein
          </p>

          <h5>Spenden</h5>
          <p>Als gemeinnützig anerkannter Verein können wir natürlich für Ihre Spende eine Bescheinigung ausstellen.
          </p>


          <div class="accordion accordion-flush" id="satzungAccordion">
            <div class="accordion-item">
              <h2 class="accordion-header" id="satzung">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#satzungCollapse" aria-expanded="false" aria-controls="satzungCollapse">
                  Satzung
                </button>
              </h2>
              <div id="satzungCollapse" class="accordion-collapse collapse" aria-labelledby="satzung"
                data-bs-parent="#satzungAccordion">
                <div class="accordion-body">
                  <h5>Satzung Pfälzerwald-Verein Hauenstein 1913 e.V.</h5>

                  <h5>§ 1 Name und Sitz des Vereins</h5>
                  <p>1.1 Der Name des Vereins ist: „Pfälzerwald-Verein Ortsgruppe Hauenstein 1913 e. V.“<br>
                    1.2 Der Verein ist politisch und konfessionell neutral.<br>
                    1.3 Der Verein hat seinen Sitz in Hauenstein.<br>
                    1.4 Der Verein ist als Ortsgruppe Mitglied des Pfälzerwald-Verein e. V., mit Sitz in Neustadt an der
                    Weinstraße.<br>
                    1.5 Das Geschäftsjahr entspricht dem Kalenderjahr.<br>
                    1.6 Der Verein ist in das Vereinsregister beim Amtsgericht Zweibrücken Registernummer VR 20477
                    eingetragen.<br>
                  </p>
                  <h5>§ 2 Gemeinnützigkeit, Zweck, Aufgaben </h5>
                  <p>2.1 Der Verein verfolgt ausschließlich und unmittelbar gemeinnützige Zwecke im Sinne des Abschnitts
                    „steuerbegünstigte Zwecke“ der Abgabenordnung.<br>
                    2.2 Zweck des Vereins ist die Förderung und Pflege:<br>
                    a) des Wanderns in allen seinen Formen.<br>
                    b) des Natur- und Umweltschutzes sowie der Landschaftspflege im Sinne der entsprechenden Bundes- und
                    Landesgesetze.<br>
                    c) der pfälzischen Heimat- und Volkskunde sowie Kultur.<br>
                    d) Der Jugend- und Familienarbeit und geeigneten Angeboten.<br>
                    2.3 Der Satzungszweck wird insbesondere verwirklicht durch:<br>
                    a) Anlage und Erhaltung der Markierungen von Wanderwegen.<br>
                    b) Erhalt des Wanderheims „Dicke Eiche“.<br>
                    c) Verbreitung von Kenntnissen über das Betreuungsgebiet des Pfälzerwald-Vereins.<br>
                    d) Wanderungen und Fahrten unter fachkundiger Führung.<br>
                    e) Durchführung eigener und Unterstützung von Maßnahmen Dritter im Natur-, Landschafts- und
                    Umweltschutz.<br>
                    f) Erhaltung lebendigen bodenständigen Brauchtums sowie Schutz von Natur- und Kulturdenkmälern.<br>
                    g) Jugendarbeit und Veranstaltungen für junge Familien mit Kindern.<br>
                    h) Lehrgänge und Veranstaltungen, die dem Vereinszweck und der Erhaltung, Pflege und Entwicklung der
                    heimatlichen Mittelgebirgs- und Waldlandschaften in ihrer Natur und Geschichte geprägten
                    charakteristischen Gestalt dienen.<br>
                    2.4 Der Verein ist selbstlos tätig, denn er verfolgt nicht in erster Linie eigenwirtschaftliche
                    Zwecke.<br>
                    2.5 Die Mittel des Vereins dürfen nur für die satzungsgemäßen Zwecke verwendet werden. Die
                    Mitglieder
                    erhalten keine Zuwendungen aus Mitteln des Vereins. Alle Vereinsämter werden grundsätzlich
                    ehrenamtlich
                    ausgeübt. Es darf keine Person durch Ausgaben, die dem Zweck des Vereins fremd sind oder durch
                    unverhältnismäßig hohe Vergütung, begünstigt werden.<br>
                    2.6 Die Ortsgruppe ist verpflichtet:<br>
                    a) Zur Unterhaltung eines regelmäßigen Wanderbetriebes. Sie hat hierzu jedes Jahr mindestens zwölf
                    Monatswanderungen zu veranstalten und in einem Wanderplan zu erfassen.<br>
                    b) Die Veranstaltungen des Pfälzerwald-Vereins e. V. (Hauptverein) in den Wanderplan der Ortsgruppe
                    aufzunehmen und den Besuch derselben zu fördern.<br>
                    c) Jährlich bis zum 1. April alle Beitragsverbindlichkeiten gegenüber dem Pfälzerwald-Verein e. V.
                    (Hauptverein) zu erfüllen.<br>
                    d) An den Bezirksversammlungen teilzunehmen.
                  </p>

                  <h5>§ 3 Erwerb der Mitgliedschaft</h5>
                  <p>3.1 Mitglied des Vereins kann jede natürliche und juristische Person werden.<br>
                    3.2 Wer die Mitgliedschaft erwerben will, hat an den Verein ein schriftliches Aufnahmegesuch zu
                    richten.<br>
                    3.3 Über die Annahme des Aufnahmegesuchs entscheidet der Vorstand durch Beschluss. Mit
                    Beschlussfassung
                    wird der gewünschte Beginn der Mitgliedschaft bestätigt und wird der Mitgliedsbeitrag fällig.<br>
                    3.4 Eine Ablehnung des Aufnahmegesuchs ist dem Antragsteller schriftlich unter Angabe der Gründe
                    mitzuteilen. Gegen die Ablehnung ist innerhalb von vier Wochen der Einspruch beim Vorstand zulässig.
                    Die
                    nächste Mitgliederversammlung entscheidet mit einfacher Stimmenmehrheit über den Einspruch.<br>
                  </p>
                  <h5>§ 4 Mitgliederarten und Beitragsregelung</h5>
                  <p>Die Ortsgruppe unterscheidet ihre Mitglieder in:<br>
                    4.1 A-Mitglieder<br>
                    a) Mitglieder, die den von der Mitgliederversammlung des Pfälzerwald-Vereins e. V. (Hauptverein)
                    festgesetzten vollen Vereinsbeitrag und dazu einen Ortsgruppenzuschlag bezahlen. Sie besitzen Recht
                    auf
                    Ehrung und alle Vereinsrechte.<br>
                    4.2 B-Mitglieder<br>
                    a) Mitglieder einer Familie. Wer als Ehegatte oder in eheähnlicher Beziehung mit einem A-Mitglied
                    zusammenlebend, der Ortsgruppe nicht als A-Mitglied,
                    sondern als Familienmitglied beitritt; wer nach seiner Verheiratung mit einem A-Mitglied seine
                    bisherige
                    Mitgliedschaft als Familienmitgliedschaft weiterführen will.
                    Die bisherige Mitgliedschaft wird angerechnet. Verwitwete B-Mitglieder können durch Erklärung nach
                    dem
                    Tode des Ehegatten/Partner*in die Mitgliedschaft als A-Mitglied fortsetzen.
                    Die Mitgliedschaft in der Familie erlischt mit dem Ende der Ehe/Beziehung. Die Mitgliedschaft in der
                    Familie ist nur innerhalb derselben Ortsgruppe möglich. Familienmitglieder
                    zahlen einen, von der Ortsgruppe festzusetzenden Ortszuschlag, jedoch keinen Vereinsbeitrag. Sie
                    besitzen Recht auf Ehrung und alle Vereinsrechte; sie bekommen keine Vereinszeitschrift
                    zugestellt.<br>
                    b) Kinder bis 14 Jahre von A- oder B-Mitgliedern gelten ebenfalls als Mitglieder einer Familie,
                    haben
                    jedoch kein Stimmrecht. B-Mitglieder können in Ämter des „Hauptvereins“ und der Ortsgruppe gewählt
                    werden.<br>
                    4.3 C-Mitglieder<br>
                    a) Jugendliche bis zum vollendeten 18. Lebensjahr (bzw. bis zum vollendeten 27. Lebensjahr in
                    Ausbildung) sind Mitglieder und zahlen den von der Jugendwartetagung
                    (siehe Satzung der Deutschen Wanderjugend im PWV) festgesetzten Beitrag und dazu einen von der
                    Ortsgruppe festzusetzenden Ortsgruppenzuschlag für Jugendliche. Sie besitzen unter 18 Jahren kein
                    Stimmrecht, jedoch Recht auf Ehrung.<br>
                    4.4 Zweitmitglieder<br>
                    a) sind natürliche Personen, die bereits in einer anderen Ortsgruppe A-, B- oder C-Mitglied sind.
                    Sie
                    können einer oder mehreren weiteren Ortsgruppen gegen Zahlung des jeweiligen Ortsgruppen-Zuschlages
                    beitreten und erwerben damit Stimmrecht und Recht auf Ehrung auf Ortsgruppenebene.<br>
                    4.5 Ehrenmitglieder können von der Zahlung von Beiträgen befreit werden.<br>
                  </p>

                  <h5>§ 5 Beendigung der Mitgliedschaft</h5>
                  <p>5.1 Die Mitgliedschaft beim Verein endet durch:<br>
                    a) Austritt<br>
                    b) Ausschluss (wegen vereinsschädigendem Verhalten, Verstöße gegen die Satzung Beitragsrückstand o.
                    ä.)<br>
                    c) Tod<br>
                    5.2 Jedes Mitglied kann mit Frist von vier Wochen seine Mitgliedschaft schriftlich beim Vorstand der
                    Ortsgruppe zum Jahresende kündigen.<br>
                    5.3 Ein Mitglied kann vom Vorstand aus wichtigem Grund durch Zweidrittel-Mehrheitsbeschluss
                    ausgeschlossen werden. Das Mitglied ist vorher zu hören.
                    Das ausgeschlossene Mitglied hat Einspruchsrecht bei der nächsten Mitgliederversammlung der
                    Ortsgruppe.
                    Die Mitgliederversammlung entscheidet mit einfacher Mehrheit über den Einspruch.<br>
                  </p>
                  <h5>§ 6 Organe des Vereins</h5>
                  <p>6.1 Organe des Vereins sind:
                    a) Die Mitgliederversammlung als oberstes Organ des Vereins
                    b) Der Vorstand
                  </p>
                  <h5>§ 7 Mitgliederversammlung</h5>
                  <p>7.1 Eine ordentliche Mitgliederversammlung, die einmal jährlich einberufen wird, ist durch die/den
                    Vorstandsvorsitzende*n, bei deren Verhinderung
                    durch ein anderes Mitgliedes des Vorstandes, mindestens 14 Tage vorher öffentlich mit der
                    Tagesordnung
                    anzukündigen. Die Ankündigung erfolgt im Hauensteiner Amtsblatt und im Internet auf der
                    Vereinshomepage.
                    <br>
                    7.2 Die Tagesordnung der ordentlichen Mitgliederversammlung muss mindestens umfassen:<br>
                    a) Jahresbericht, Rechnungslegung, Entlastung.<br>
                    b) Wünsche und Anträge, die spätestens acht Tage vor der Versammlung beim Vorstand eingereicht
                    werden
                    müssen.<br>
                    c) Alle drei Jahre Neuwahlen des Vorstandes und von zwei Rechnungsprüfer*innen.<br>
                    d) Gegebenenfalls Festsetzung der Ortsgruppenzuschläge.<br>
                    7.3 Die Mitgliederversammlung wird durch die/den Vorstandsvorsitzende*n, bei deren Verhinderung
                    durch
                    ein anderes Mitglied des Vorstandes, geleitet. Die Mitgliederversammlung besteht aus Mitgliedern,
                    die je
                    eine Stimme haben, soweit in dieser Satzung nichts anderes geregelt ist.<br>
                    7.4 Die Mitgliederversammlung ist nicht öffentlich. Die/der Versammlungsleiter*in kann Gäste
                    zulassen.<br>
                    7.5 Die Mitgliederversammlung ist ohne Rücksicht auf die Anzahl der erschienen Mitglieder
                    beschlussfähig.
                    7.6 Eine außerordentliche Mitgliederversammlung ist durch die/den Vorstandsvorsitzende*n, bei deren
                    Verhinderung durch ein anderes Mitglied des Vorstandes, mindestens 14 Tage mit der
                    Tagesordnung öffentlich im Hauensteiner Amtsblatt und im Internet auf der Vereinshomepage
                    anzukündigen.
                    Sie muss stattfinden, wenn dies ein Viertel aller Mitglieder schriftlich beim Vorstand
                    beantragt.<br>
                  </p>
                  <h5>§ 8 Jugendgruppe</h5>
                  <p>8.1 Die Ortsgruppe strebt die Bildung einer Jugendgruppe an. Diese bildet eine eigene Gruppe
                    innerhalb
                    der Ortsgruppe.
                  </p>
                  <h5>§ 9 Vorstand</h5>
                  <p>9.1 Der Vorstand des Vereins im Sinne von § 26 BGB vertritt den Verein gerichtlich und
                    außergerichtlich. Jedes Vorstandmitglied ist alleine vertretungsberechtigt. <br>
                    9.2 Der Vorstand besteht aus: <br>
                    a) Vorsitzende/r des Vorstandes<br>
                    b) Vorstand/Vorständin Finanzen<br>
                    c) Vorstand/Vorständin Wandern / aktives Vereinsleben<br>
                    d) Vorstand/Vorständin Hüttenbetrieb / Bauen / Liegenschaften <br>
                    e) Vorstand/Vorständin Natur-/Umweltschutz<br>
                    9.3 Aufgabe des Vorstandes ist die Leitung und Geschäftsführung des Vereines. Er ist für alle
                    Aufgaben
                    zuständig, die nicht durch Satzung einem anderen Vereinsorgan zugewiesen sind.<br>
                    9.4 Jede*r Vorsitzende*n kann für ihr/sein Ressort einen Fachausschuss nach dem Vorbild des
                    Pfälzerwald-Vereins e. V. (Hauptverein) bilden.<br>
                    9.5 Der Vorstand wird durch die Mitgliederversammlung auf die Dauer von drei Jahren gewählt. <br>
                    9.6 Der Vorstand bleibt solange im Amt, bis Nachfolgerinnen/Nachfolger gewählt wurden.<br>
                    9.7 Bei vorzeitigem Ausscheiden eines Vorstandes kann der Vorstand mit einfacher Stimmenmehrheit
                    eine
                    kommissarische Vertretung bis zur nächsten ordentlichen Mitgliederversammlung wählen.<br>
                    9.8 Die/der Vorstandsvorsitzende, oder bei deren Verhinderung jedes andere Vorstandsmitglied, lädt
                    mindestens zweimal jährlich zur Vorstandssitzung ein. Die Einladungsfrist beträgt zwei Wochen.
                    Sie sind dazu verpflichtet, wenn es die Mehrheit der Mitglieder des Vorstandes von ihnen
                    verlangt.<br>
                    9.9 Innerhalb des Vorstandes und etwaiger Fachausschüsse wird ein*e Schriftführer*in gewählt.<br>
                    9.10 Der Vorstand ist beschlussfähig, wenn mindestens drei seiner Mitglieder anwesend sind.<br>
                    9.11 Ein Protokoll der Sitzung (s.u. § 11) ist anzufertigen.
                  </p>
                  <h5>§ 10 Ehrungen</h5>
                  <p>10.1 Es gilt die Ehrenordnung des Pfälzerwald-Vereins e.V. (Hauptverein).</p>

                  <h5>§ 11 Abstimmungen und Niederschriften</h5>
                  <p>11.1 Die Abstimmungen und Wahlen erfolgen durch Handzeichen mit einfacher Mehrheit der abgegebenen
                    Stimmen. <br>
                    11.2 Stimmenthaltungen und ungültige Stimmen zählen bei der Feststellung der Stimmenmehrheit nicht
                    mit.
                    <br>
                    11.3 Bei Stimmengleichheit entscheidet die Stimme der/s Versammlungsleiterin/Versammlungsleiters.
                    <br>
                    11.4 Auf Verlangen einer Stimmberechtigten Person muss über einen Antrag geheim abgestimmt/gewählt
                    werden. <br>
                    11.5 Bei geheimer Abstimmung gilt bei Stimmengleichheit der Antrag als abgelehnt. <br>
                    11.6 Über die Mitgliederversammlungen, die Sitzungen des Vorstandes und der Fach-/Ressortausschüsse
                    sind
                    Niederschriften anzufertigen und jeweils von der Versammlungsleitung und der/dem Schriftführer*in zu
                    unterzeichnen.<br>
                  </p>
                  <h5>§ 12 Satzungsänderung</h5>
                  <p>12.1 Vorschläge zu Änderungen und Ergänzungen der Satzung müssen allen Mitgliedern der Ortsgruppe
                    im
                    Rahmen der Einberufung zur Mitgliederversammlung bekannt gegeben werden. <br>
                    Dann kann eine Satzungsänderung mit einer Mehrheit von zwei Dritteln der anwesenden
                    stimmberechtigten
                    Mitglieder beschlossen werden. <br>
                    12.2 Änderungen und Ergänzungen sollen nur im Einvernehmen mit dem Vorstand des Pfälzerwald-Vereins
                    e.
                    V. (Hauptverein) durchgeführt werden. <br>
                    12.3 Bei mangelndem Einvernehmen der Satzung der Ortsgruppe mit den eingegangenen satzungsmäßigen
                    Verpflichtungen gegenüber dem Pfälzerwald-Verein e. V. (Hauptverein) kann der Vorstand des
                    Pfälzerwald-Vereins e. V. (siehe § 7 der Satzung des Pfälzerwald-Vereins e. V.) die Ortsgruppe
                    ausschließen.<br>
                  </p>
                  <h5>§ 13 Auflösung des Vereins</h5>
                  <p> 13.1 Die Auflösung des Vereins kann durch eine Mitgliederversammlung beschlossen werden.<br>
                    13.2 Der Hauptvorstand des Pfälzerwald-Vereins e. V. (Hauptverein) muss hiervon benachrichtigt
                    werden.
                    <br>
                    13.3 Die Mitgliederversammlung muss den Mitgliedern mindestens einen Monat vorher bekannt gegeben
                    werden. <br>
                    13.4 Drei Viertel der abgegebenen Stimmen müssen den Antrag bei der Mitgliederversammlung bejahen.
                    <br>
                    13.5 Bei Auflösung des Vereins oder Wegfall der Voraussetzungen für die Gemeinnützigkeit, fällt das
                    Vermögen an die Ortsgemeinde Hauenstein, die es unmittelbar und ausschließlich für gemeinnützige
                    oder
                    mildtätige Zwecke zu verwenden hat. <br>
                  </p>
                  <h5>§ 14 Inkrafttreten</h5>
                  <p>14.1 Die am 27.10.2023 von der Ortsgruppe Hauenstein e. V. des Pfälzerwald-Vereins e. V.
                    beschlossene
                    Satzung tritt bei Eintragung ins Vereinsregister in Kraft. Gleichzeitig tritt die Satzung vom
                    28.01.1995
                    außer Kraft.
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>