<div class="container-fluid mt-3">
    <form id="contactForm" (ngSubmit)="onSubmit()" [formGroup]=contactFormGroup [hidden]="hideForm">
        <input type="text" name="name" formControlName="name" style="display:none !important" tabindex="-1" autocomplete="invalidValue">
        <div class="form-row justify-content-md-center">
            <div class="col-md-4 mb-3">
                <label for="firstName">Vorname*</label>
                <input type="text" class="form-control" formControlName="firstName" placeholder="Vorname" required>
                <div class="text-danger" [hidden]="contactFormGroup.get('firstName').valid || !submitted">
                    Bitte geben Sie Ihren Vornamen an.
                </div>
            </div>
            <div class="col-md-4 mb-3">
                <label for="lastName">Nachname*</label>
                <input type="text" class="form-control" formControlName="lastName" placeholder="Nachname" required>
                <div class="text-danger" [hidden]="contactFormGroup.get('lastName').valid || !submitted">
                    Bitte geben Sie Ihren Nachnamen an.
                </div>
            </div>
        </div>

        <div class="form-row justify-content-md-center">
            <div class="col-md-4 mb-3">
                <label for="email">E-Mail*</label>
                <input type="email" class="form-control" formControlName="email" placeholder="E-Mail" required>
                <div class="text-danger" [hidden]="contactFormGroup.get('email').valid || !submitted">
                    Bitte geben Sie Ihre E-Mail an.
                </div>
            </div>
            <div class="col-md-4 mb-3">
                <label for="phoneNumber">Telefonnummer</label>
                <input type="tel" class="form-control" formControlName="phoneNumber" placeholder="Telefonnummer">
            </div>
        </div>

        <div class="form-row justify-content-md-center">
            <div class="col-md-4 mb-3">
                <label class="my-1 mr-2" for="matter">Ihr Anliegen*</label>
                <select class="custom-select my-1 mr-sm-2" formControlName="matter" required>

                    <option *ngFor="let matterEntry of Matters | keyvalue" value="{{matterEntry.value}}">{{matterEntry.key}}</option>
                </select>
                <div class="text-danger" [hidden]="contactFormGroup.get('matter').valid || !submitted">
                    Bitte wählen Sie Ihr Anliegen aus.
                </div>
            </div>
            <div class="col-md-4"></div>
        </div>
        <div class="form-row justify-content-md-center">
            <div class="col-md-8 mb-3">
                <label for="message">Ihre Nachricht*</label>
                <textarea class="form-control" formControlName="message" rows="3" required></textarea>
                <div class="text-danger" [hidden]="contactFormGroup.get('message').valid || !submitted">
                    Bitte geben Sie Ihre Nachricht an uns ein.
                </div>
            </div>

        </div>

        <div class="form-row justify-content-md-center">
            <div class="col-md-8">
                <div class="form-check mb-3">
                    <input class="form-check-input" type="checkbox" value="" id="newsletter" formControlName="newsletter">
                    <label class="form-check-label" for="newsletter">
                        Ich möchte den Newsletter des PWV Hauenstein auf die angegebene E-Mail Adresse erhalten
                    </label>
                </div>
            </div>
        </div>

        <div class="form-row justify-content-md-center">
            <div class="col-md-8">
                <div class="form-check mb-3">
                    <input class="form-check-input" type="checkbox" value="" id="readPrivacy" formControlName="readPrivacy">
                    <label class="form-check-label" for="readPrivacy">
                        Ich akzeptiere die <a routerLink="/datenschutz" routerLinkActive="active">Datenschutzbedingungen</a>*
                    </label>
                    <div class="text-danger" [hidden]="contactFormGroup.get('readPrivacy').valid || !submitted">
                        Bitte akzeptieren Sie die Datenschutzbedingungen.
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row justify-content-md-center mb-3">
            <div class="text-danger col-md-8" [hidden]="hideFailedText">
                Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es in Kürze noch einmal.
            </div>
        </div>
        <div class="form-row justify-content-md-center mb-3">
            <button [disabled]="!contactFormGroup.valid && submitted" id="submitButton" class="btn btn-secondary col-md-1" type="submit">Abschicken</button>
            <div class="col-md-7"></div>
        </div>
        <div class="form-row justify-content-md-center">
            <div class="col-md-8 mb-3">
                *Pflichtangabe
            </div>
        </div>
    </form>
    <div class="row justify-content-md-center">
        <div class="col-md-8">
            <div [hidden]="hideThankYou">
                Danke für Ihre Nachricht. Wir melden uns so schnell wie möglich bei Ihnen.
            </div>
        </div>
    </div>

</div>