import { Component, OnInit } from '@angular/core';
import { faMapMarked, faUser, faClock, faHourglass, faAngleUp, faAngleDown, faHiking } from '@fortawesome/free-solid-svg-icons'
@Component({
    selector: 'app-huette',
    templateUrl: './huette.component.html',
    styleUrls: ['./huette.component.css'],
    standalone: false
})
export class HuetteComponent implements OnInit {

  constructor() { }
  faMapMarked = faMapMarked
  faUser = faUser
  faClock = faClock
  faHourglass = faHourglass
  faAngleUp = faAngleUp
  faAngleDown = faAngleDown
  faHiking = faHiking
  ngOnInit(): void {
  }

}
